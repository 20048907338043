import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import useAuth from '../../hooks/useAuth'
import { sortBy } from 'lodash'
import { useLocation } from 'react-router-dom'

export const useDistributorActions = (id) => {
  const navigateTo = useNavigateTo()
  const licenses = useDistributorLicenses(id)
  const { logout } = useAuth()
  const location = useLocation()

  return useMemo(
    () => {
      const unassignedLicensesCount = licenses
        .filter(({ owner }) => !owner)
        .map(({ distributor }) => distributor === id)
        .length
      return [
        {
          label: 'buttonLabelUpdateCert',
          handleOnClick: () => navigateTo(privateRoutes.updateCertificate, { id })
        },
        {
          label: 'btnLabelCreateOwner',
          handleOnClick: () => navigateTo(privateRoutes.createOwner, { id }),
          notification: 'notification',
          unassignedLicensesCount
        },
        {
          label: 'btnLabelLogOut',
          handleOnClick: logout
        }
      ]
    },
    [id, licenses, location]
  )
}

export const useDistributor = () => {
  const [state] = useBackOffice()

  return state.distributors.byId[state.distributor]
}

export const useDistributorSelect = () => {
  const [state] = useBackOffice()

  return useMemo(() => {
    const distributors = Object.entries(state.distributors.byId).map(([key, { name, id, certificate }]) => ({ name, id, missingCertificate: !certificate }))
    const sortedDistributors = sortBy(distributors, ({ name }) => name)

    return {
      options: sortedDistributors,
      selected: state.distributor
    }
  }, [state.distributors.byId, state.distributor])
}

export const useDistributorLicenses = (id) => {
  const [state] = useBackOffice()

  return useMemo(
    () => state.licenses.ids
      .filter((licenseId) => state.licenses.byId[licenseId].distributor === id)
      .map((licenseId) => state.licenses.byId[licenseId]),
    [id, state.licenses]
  )
}
