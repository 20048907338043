import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { formatValidUntil } from '../../helpers'

const License = ({ license }) =>
  <TableRow>
    <TableCell style={{ width: 62 }} />
    <TableCell
      align='left'
    >
      {license.licenseNumber}
    </TableCell>
    <TableCell
      align='left'
    >
      {formatValidUntil(license.validUntil)}
    </TableCell>
    {/* <TableActionsCell /> */}
  </TableRow>

export default License
