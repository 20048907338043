import { TableCell } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import License from './License'
import { useTranslation } from 'react-i18next'

const LicenseTable = ({ items }) => {
  const [state] = useBackOffice()
  const { t } = useTranslation('oasisBackoffice')

  return (
    <TableContainer style={{ paddingBlock: '16px 32px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='62px' />
            <TableCell>{t('license.tableHeaderNumber')}</TableCell>
            <TableCell width='280px'>{t('common.expirationDate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((id) => <License license={state.licenses.byId[id]} key={id} />)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LicenseTable
