import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React from 'react'

const ButtonWithLoader = ({ children, disabled, loading, ...props }) => {
  const endIcon = !!loading && (
    <CircularProgress
      variant='indeterminate'
      color='inherit'
      size={16}
    />
  )

  return (
    <Button
      disabled={disabled || loading}
      endIcon={endIcon}
      {...props}
    >
      {children}
    </Button>
  )
}

ButtonWithLoader.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

ButtonWithLoader.defaultProps = {
  disabled: false,
  loading: false
}

export default ButtonWithLoader
