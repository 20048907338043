import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { AuthContext } from '../hooks/useAuth'
import useAuthProvider from '../hooks/useAuthProvider'
import { useTranslation } from 'react-i18next'

const AuthProvider = ({ children }) => {
  const { t } = useTranslation('oasisBackoffice')

  const snackbar = useSnackbar()

  const handleOnWarning = useCallback(
    (refreshSession) => {
      const handleOnClick = (key) => {
        snackbar.closeSnackbar(key)
        refreshSession()
      }

      snackbar.enqueueSnackbar(
        t('authProvider.youWillBeLogOut'),
        {
          persist: true,
          preventDuplicate: true,
          variant: 'warning',
          action: (key) =>
            <Button
              size='small'
              onClick={() => handleOnClick(key)}
              color='inherit'
              variant='outlined'
            >
              {t('authProvider.stayLoggedIn')}
            </Button>
        }
      )
    },
    [snackbar]
  )

  const handleOnExpired = useCallback(
    () => {
      snackbar.closeSnackbar()
      snackbar.enqueueSnackbar(
        t('authProvider.youHaveBeenLogOut'),
        {
          persist: true,
          preventDuplicate: true,
          variant: 'info',
          action: (key) =>
            <Button
              size='small'
              onClick={() => snackbar.closeSnackbar(key)}
              color='inherit'
              variant='outlined'
            >
              OK
            </Button>
        }
      )
    },
    [snackbar]
  )

  const auth = useAuthProvider({
    onLogin: snackbar.closeSnackbar,
    onLogout: snackbar.closeSnackbar,
    onSessionExpired: handleOnExpired,
    onSessionRefresh: snackbar.closeSnackbar,
    onSessionWarning: handleOnWarning
  })

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
