import React from 'react'
import useAccordionToggle from '../../hooks/useAccordionToggle'
import Shop from './Shop'

const ShopsList = ({ shops }) => {
  const [activeShop, handleOnClick] = useAccordionToggle(null)

  return (
    <>
      {shops.map((id) =>
        <Shop
          key={id}
          id={id}
          expanded={activeShop === id}
          onClick={handleOnClick}
        />)}
    </>
  )
}

export default ShopsList
