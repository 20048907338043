import Typography from '@material-ui/core/Typography'
import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCreateOwner } from '../shop/hooks'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  typography: {
    color: theme.palette.primary.main,
    fontWeight: '500'
  },
  button: {
    cursor: 'pointer',
    borderColor: '#B0B0B0',
    marginTop: theme.spacing(3)
  }
}))

const NoOwnersView = ({ distributorId }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  const handleCreateOwner = useCreateOwner(distributorId)

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      paddingTop={15}
    >
      <Typography className={classes.typography}>
        {t('common.noOwners')}
      </Typography>
      <Button
        variant='outlined'
        size='medium'
        color='primary'
        aria-label='create owner button'
        className={classes.button}
        onClick={handleCreateOwner}
      >
        {t('common.buttonNoOwners')}
      </Button>
    </Box>
  )
}

export default NoOwnersView
