import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { appVersion } from './consts'
import LanguageSelector from '../LanguageSelector'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '0.7rem',
    flexShrink: 0,
    color: 'rgba(114,114,114,0.8)',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  column: {
    height: '100%'
  },
  link: {
    color: 'inherit'
  },
  version: {
    marginRight: theme.spacing(1.5),
    fontSize: 'inherit'
  }
}))

const Footer = () => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod'

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='flex-end'
      className={classes.container}
    >
      <Grid
        xs
        item
        container
        justifyContent='flex-start'
        alignItems='center'
        className={classes.column}
      >
        {!isProduction &&
          <Box ml={4}>
            <Typography variant='body1' color='error'>{t('testEnvironment')}</Typography>
          </Box>}
      </Grid>
      <Grid
        xs
        item
        container
        justifyContent='center'
        alignItems='center'
        className={classes.column}
      >
        <Link
          className={classes.link}
          href='https://thegoodcode.io/'
        >
          Made by TheGoodCode with ♥
        </Link>
      </Grid>
      <Grid
        xs
        item
        container
        className={classes.column}
        justifyContent='flex-end'
        alignItems='center'
      >
        <Typography className={classes.version}>
          v{appVersion}
        </Typography>
        <LanguageSelector />
      </Grid>
    </Grid>
  )
}

export default Footer
