import { createTheme } from '@material-ui/core/styles'
import color from 'color'

const primary = '#4d6ec9'
const secondary = '#CD7C11'
// const primary = '#DD7023' // logoOrange
const background = '#efefef'

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: color(primary).lighten(0.2).hex(),
      dark: color(primary).darken(0.2).hex()
    },
    secondary: {
      main: secondary
    },
    success: {
      main: '#70C66D'
    },
    background: {
      default: background
    }
  }
})

export default theme
