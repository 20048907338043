import { useState } from 'react'

const useAccordionToggle = (defaultActive = null) => {
  const [active, setActive] = useState(defaultActive)

  const toggleActive = (item) => {
    if (active === item) {
      setActive(null)
    } else {
      setActive(item)
    }
  }

  return [active, toggleActive]
}

export default useAccordionToggle
