import { noop } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { fileInput, passwordInput } from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'
import { useDistributor } from '../../components/distributor/hooks'

const fields = {
  passphrase: { ...passwordInput, label: 'fieldLabelUploadDistributorCert' },
  confirmPassphrase: {
    ...passwordInput,
    label: 'fieldLabelUploadDistributorCertConfirm',
    match: 'passphrase'
  },
  certificate: { ...fileInput, label: 'fieldLabelCertificateFile', required: true, inputProps: { accept: '.p12' } }
}

const emptyForm = fieldsToForm(fields)

const UploadDistributorCertificate = ({ onSubmit, onCancel }) => {
  const handleSubmit = async (values) => {
    try {
      await onSubmit(values)
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  const { t } = useTranslation('oasisBackoffice')
  const distributor = useDistributor()

  const certificateAssigned = distributor.certificate
  const title = certificateAssigned ? 'titleUpdateDistributorCertificate' : 'titleUploaderDistributorCertificate'
  return (
    <ModalScreen
      hideBackdrop={!certificateAssigned}
      onClose={certificateAssigned ? onCancel : noop}
      openModal
      title={t(`actionDialogs.${title}`)}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={emptyForm}
        onCancel={certificateAssigned && onCancel}
        onSubmit={handleSubmit}
        submitLabel='buttonLabelUpload'
      />
    </ModalScreen>
  )
}

UploadDistributorCertificate.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

UploadDistributorCertificate.defaultProps = {
  onSubmit: noop,
  onCancel: noop
}

export default UploadDistributorCertificate
