import { generatePath, useNavigate, useLocation } from 'react-router-dom'

const useNavigateTo = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (route, params = {}) => {
    const targetPath = generatePath(route, params)

    if (targetPath !== location.pathname) {
      navigate(targetPath)
    }
  }
}

export default useNavigateTo
