import { sortBy } from 'lodash'
import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import { useAssignLicenses, useShopAssignableLicenses } from '../license/hooks'
import useAuth from '../../hooks/useAuth'
import { UserRoles } from '../../private/const'

export const useShopMenuActions = (id, shopLocked) => {
  const navigateTo = useNavigateTo()
  const licenses = useShopAssignableLicenses(id)
  const assignLicenses = useAssignLicenses(licenses, () => navigateTo(privateRoutes.assignLicensesToShop, { id }))
  const { user: { userRole } } = useAuth()

  return useMemo(
    () => {
      const distributorActions = [
        {
          label: 'assignEmployees',
          onClick: () => navigateTo(privateRoutes.assignEmployeesToShop, { id })
        },
        assignLicenses,
        {
          label: 'deleteShop',
          onClick: () => navigateTo(privateRoutes.deleteShop, { id })
        },
        {
          label: 'editShop',
          onClick: () => navigateTo(privateRoutes.editShop, { id })
        },
        {
          label: 'editShopCredentials',
          onClick: () => navigateTo(privateRoutes.editShopCredentials, { id })
        },
        {
          label: 'freeShopLicenses',
          onClick: () => navigateTo(privateRoutes.freeLicense, { id })
        }
      ]

      const adminAction = {
        label: shopLocked ? 'unlockShop' : 'lockShop',
        onClick: () => navigateTo(privateRoutes.lockShop, { id })
      }

      userRole === UserRoles.Admin && distributorActions.push(adminAction)

      return distributorActions
    },
    [id, licenses, navigateTo, userRole, shopLocked]
  )
}

export const useShops = (ownerId) => {
  const [{ shops: { ids = [], byId } }] = useBackOffice()

  return useMemo(
    () => ids.filter((id) => byId[id].owner === ownerId),
    [ownerId, ids, byId]
  )
}

export const useSortedShops = (ownerId) => {
  const shopIds = useShops(ownerId)
  const [{ shops: { byId } }] = useBackOffice()

  return useMemo(
    () => sortBy(shopIds, (id) => byId[id].name.toLocaleLowerCase()),
    [ownerId, shopIds, byId]
  )
}

export const useShop = (id) => {
  const [{ shops: { byId } }] = useBackOffice()

  return byId[id]
}

export const useCreateShop = (id) => {
  const navigateTo = useNavigateTo()
  return () => navigateTo(privateRoutes.createShop, { id })
}

export const useCreateOwner = (id) => {
  const navigateTo = useNavigateTo()
  return () => navigateTo(privateRoutes.createOwner, { id })
}
