import jwtSession from './jwtSession'
import logger from '../../logger'

const session = jwtSession({
  autoStart: true,
  storageKey: 'OASIS_API_TOKEN',
  logger
})

export default session
