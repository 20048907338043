import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import i18n from './i18next/src/react'

const root = ReactDOM.createRoot(document.getElementById('root'))

i18n().then(
  () => root.render(
    <>
      <CssBaseline />
      <App />
    </>
  )
)

reportWebVitals()
