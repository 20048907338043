import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ButtonWithLoader from '../../common/ButtonWithLoader'

const ModalActionButtons = ({ firstButtonLabel, action, secondButtonLabel, close, disabled, isActionInProgress }) => {
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Grid
      container
      justifyContent='center'
      spacing={4}
    >
      <Grid item>
        <Button
          variant='outlined'
          color='primary'
          onClick={close}
        >
          {t(`oasisForm.${secondButtonLabel}`)}
        </Button>
      </Grid>
      {action && (
        <Grid item>
          <ButtonWithLoader
            loading={isActionInProgress}
            variant='contained'
            color='primary'
            onClick={action}
            disabled={disabled}
          >
            {t(`oasisForm.${firstButtonLabel}`)}
          </ButtonWithLoader>
        </Grid>
      )}
    </Grid>
  )
}

ModalActionButtons.propTypes = {
  firstButtonLabel: PropTypes.string,
  action: PropTypes.func,
  secondButtonLabel: PropTypes.string,
  close: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isActionInProgress: PropTypes.bool
}

ModalActionButtons.defaultProps = {
  firstButtonLabel: 'buttonLabelAction',
  secondButtonLabel: 'buttonLabelCancel',
  disabled: false,
  isActionInProgress: false
}

export default ModalActionButtons
