import apiFetch from './helpers'

const publicApi = ({ baseUrl, logger }) => {
  const { post } = apiFetch({ baseUrl, logger })

  return {
    signUp: (body) => post('register', body),
    login: (body) => post('login', body),
    forgotPassword: (body) => post('forgotPassword', body),
    resetPassword: (body) => post('resetPassword', body)
  }
}

export default publicApi
