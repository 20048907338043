import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '75vh'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  table: {
    maxHeight: '50vh'
  },
  cell: {
    fontSize: '16px'
  }
}))
export const useRowStyles = makeStyles({ hover: { cursor: 'pointer' } })
