import { format, isValid, parseISO } from 'date-fns/fp'
import { flowRight, identity } from 'lodash/fp'
import { OasisApiCallStatuses } from './private/const'

const formatDate = (emptyMessage) => (date, transform = identity) => flowRight(
  (date) => isValid(date) ? format('dd.MM.yyyy, HH:mm')(date) : transform(emptyMessage),
  parseISO
)(date)

export const formatLastLogin = formatDate('neverLoggedIn')

export const formatActivatedAt = formatDate('accountNotActivated')

export const formatValidUntil = flowRight(
  (date) => isValid(date) ? format('dd.MM.yyyy')(date) : null,
  parseISO
)

export const formatCreatedAt = formatDate('invalidDate')

export const isAuthErrorCode = (status) => status === 401 || status === 403

export const isConflictErrorCode = (status) => status === 409

export const oasisApiCallResults = (calls) => {
  const results = calls.reduce((acc, call) => {
    call.status === OasisApiCallStatuses.success
      ? acc.successes.push(call)
      : acc.errors.push(call)
    return acc
  }, { successes: [], errors: [] })

  return [results.successes, results.errors]
}

export const isFeatureEnabled = (userFeatures, requiredFeatures) =>
  requiredFeatures.some(feature => userFeatures.includes(feature))
