export const isFeatureActivated = (currentFeatures, feature) =>
  (currentFeatures & feature) === feature

export const setFeature = (currentFeatures, feature, newValue) => {
  if (newValue) {
    return currentFeatures | feature
  } else {
    return currentFeatures & ~feature
  }
}
