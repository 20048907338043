import { identity } from 'lodash'
import consoleLogger from '../consoleLogger'

export class ApiError extends Error {
  constructor (error, status, message) {
    super('API call failed')
    this.error = error
    this.status = status
    this.message = message
  }
}

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8'
}

export const defaultOptions = {
  logger: consoleLogger,
  transformResponse: identity
}

export const reCaptchaActions = {
  login: 'login',
  register: 'register',
  forgotPassword: 'forgotPassword',
  resetPassword: 'resetPassword'
}
