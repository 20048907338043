import Checkbox from '@material-ui/core/Checkbox'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { formatActivatedAt, formatLastLogin, oasisApiCallResults } from '../../helpers'
import TableActionsCell from '../common/TableActionsCell'
import { useEmployeeMenuActions } from './hooks'
import logger from '../../logger'
import { ACTIVATE_EMPLOYEE_COMPLETE } from '../../reducer/actions'
import { api } from '../../services/api'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import AuthorizedView from '../common/AuthorizedView'
import useNavigateTo from '../../hooks/useNavigateTo'
import privateRoutes from '../../private/routes'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { AvailableFeatures } from '../../private/const'
const {
  activateEmployee
} = api

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 'auto',
    padding: 0
  }
}))

const EmployeeRow = ({ item, onSelect, selectable, selected, scanCount, shopId, oasisApiCalls = [] }) => {
  const [, dispatch] = useBackOffice()
  const { t } = useTranslation('oasisBackoffice')
  const navigateTo = useNavigateTo()
  const classes = useStyles()

  const handleActivate = useCallback(
    async (employeeId) => {
      logger.info(`Activating employee '${employeeId}`, { employeeId })
      dispatch({
        type: ACTIVATE_EMPLOYEE_COMPLETE,
        payload: await activateEmployee(employeeId)
      })
    },
    [dispatch]
  )

  const handleOpenOasisApiErrorsModal = useCallback(
    () => navigateTo(privateRoutes.oasisApiErrors, { id: shopId, employeeId: item.id }),
    [shopId, item.id]
  )

  const translate = useCallback(
    (value) => t(`employee.${value}`),
    []
  )

  const menuActions = useEmployeeMenuActions({ id: item.id, handleActivate })

  const [successOasisApiCalls, errorOasisApiCalls] = oasisApiCallResults(oasisApiCalls)
  const oasisApiSuccessCount = successOasisApiCalls.length
  const oasisApiErrorCount = errorOasisApiCalls.length

  return (
    <TableRow hover={selectable}>
      <TableCell style={{ width: 62 }}>
        {selectable && (
          <Checkbox
            id={item.id}
            color='primary'
            disabled={item.disabled}
            checked={selected}
            onChange={() => onSelect(item.id)}
          />
        )}
      </TableCell>
      <TableCell>
        {item.email}
      </TableCell>
      <AuthorizedView>
        <TableCell>
          {scanCount || '-'}
        </TableCell>
      </AuthorizedView>
      <AuthorizedView features={[AvailableFeatures.oasisApiCounting]}>
        <TableCell align='center'>
          <span>{oasisApiSuccessCount || '-'} / </span>
          <Button
            variant='text'
            color='secondary'
            className={classes.button}
            disableRipple
            disabled={!oasisApiErrorCount}
            onClick={handleOpenOasisApiErrorsModal}
          >
            {oasisApiErrorCount || '-'}
          </Button>
        </TableCell>
      </AuthorizedView>
      <TableCell>
        {formatLastLogin(item.lastLoginAt, translate)}
      </TableCell>
      <TableCell>
        {formatActivatedAt(item.activatedAt, translate)}
      </TableCell>
      <TableActionsCell actions={menuActions} />
    </TableRow>
  )
}

export default EmployeeRow
