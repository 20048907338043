import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Paper, Backdrop, Fade, Grid, Typography, Divider, Container } from '@material-ui/core'
import ModalActionButtons from './ModalActionButtons'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'scroll',
    paddingTop: '50px'
  },
  paper: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  }
}))

const ModalScreen = ({ title, children, button: { label, onClick }, openModal, onClose, withForm, ...props }) => {
  const classes = useStyles()

  const handleClose = useCallback(
    () => {
      onClose && onClose()
    },
    [onClose]
  )

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      {...props}
    >
      <Fade in={openModal}>
        <Grid item xs={11} sm={10} md={8} lg={6} xl={4}>
          <Paper elevation={24} className={classes.paper}>
            <Grid
              xs={12} sm={10} md={8}
              container
              item
              direction='column'
              alignItems='center'
              spacing={4}
            >
              <Grid item>
                {title &&
                  <Typography
                    variant='h5'
                    color='primary'
                    gutterBottom
                  >
                    {title}
                    <Divider className={classes.divider} />
                  </Typography>}
              </Grid>
              <Grid item container alignItems='stretch'>
                <Container>
                  {children}
                </Container>
              </Grid>
              {!withForm && (
                <Grid item>
                  <ModalActionButtons
                    firstButtonLabel={label}
                    action={onClick}
                    close={onClose}
                  />
                </Grid>)}
            </Grid>
          </Paper>
        </Grid>
      </Fade>
    </Modal>
  )
}

ModalScreen.propTypes = {
  title: PropTypes.node,
  button: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func
  }),
  openModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  withForm: PropTypes.bool
}

ModalScreen.defaultProps = {
  title: 'Title',
  button: {
    label: 'buttonLabelAction'
  },
  openModal: false,
  withForm: false
}

export default ModalScreen
