import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

const useStyles = makeStyles({
  root: {
    cursor: 'pointer'
  }
})

const SearchField = ({ label, onChange, value }) => {
  const classes = useStyles()
  const handleOnChange = useCallback(
    (event) => onChange(event.target.value || ''),
    [onChange]
  )

  const endAdornment = value
    ? <ClearIcon onClick={() => onChange('')} classes={classes} />
    : <SearchIcon />

  return (
    <TextField
      value={value}
      onChange={handleOnChange}
      style={{ width: 320, backgroundColor: 'white' }}
      InputProps={{
        startAdornment: <InputAdornment position='start'>{label}:</InputAdornment>,
        endAdornment: <InputAdornment position='end'>{endAdornment}</InputAdornment>
      }}
      variant='outlined'
    />
  )
}

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string
}

SearchField.defaultProps = {
  label: 'Search'
}

export default SearchField
