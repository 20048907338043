import { Backdrop, Box, Divider, Fade, Grid, Modal, Paper, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import ModalActionButtons from '../modalComponents/ModalActionButtons'
import AssignLicensesTable from './AssignLicensesTable'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const AssignLicenses = ({ onCancel, onSubmit, label, licenses }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  const title = <Box>{label || t('modals.assignLicensesTitle')}</Box>

  const [currentSelectedLicenses, setCurrentSelectedLicenses] = useState(licenses.reduce((acc, {
    id,
    used
  }) => ({ ...acc, [id]: used }), {}))

  const onSelect = (id) => {
    setCurrentSelectedLicenses({
      ...currentSelectedLicenses,
      [id]: !currentSelectedLicenses[id]
    })
  }

  const handleOnSubmit = () => {
    const newLicenses = Object
      .entries(currentSelectedLicenses)
      .filter(([id, selected]) => selected)
      .map(([id]) => id)

    onSubmit(newLicenses)
  }

  return (
    <Modal
      open
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in>
        <Grid item xs={11} sm={10} md={8} lg={6} xl={4}>
          <Paper elevation={24} className={classes.paper}>
            <Grid
              xs={12}
              md={10}
              container
              item
              direction='column'
              alignItems='center'
              spacing={4}
            >
              <Grid item>
                <Typography variant='h5' color='primary' gutterBottom>
                  {title}
                  <Divider className={classes.divider} />
                </Typography>
              </Grid>
              <Grid item container alignItems='stretch'>
                <AssignLicensesTable
                  licenses={licenses}
                  selected={currentSelectedLicenses}
                  onSelect={onSelect}
                />
              </Grid>
              <Grid item>
                <ModalActionButtons
                  firstButtonLabel='buttonLabelAssign'
                  action={handleOnSubmit}
                  close={onCancel}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Fade>
    </Modal>
  )
}

export default AssignLicenses
