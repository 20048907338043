import React, { useMemo } from 'react'
import WideModalScreen from '../../../components/modals/modalComponents/WideModalScreen'
import OasisApiCallErrorsTable from './OasisApiCallErrorsTable'
import { useTranslation } from 'react-i18next'
import ModalActionButtons from '../../../components/modals/modalComponents/ModalActionButtons'
import { useBackOffice } from '../../hooks/useBackOffice'
import { useParams } from 'react-router'
import { oasisApiCallResults } from '../../../helpers'
import { sortBy } from 'lodash'

const OasisApiCallErrorsModal = ({ onCancel }) => {
  const params = useParams()
  const [{ oasisApiCalls, employees }] = useBackOffice()
  const { t } = useTranslation('oasisBackoffice')

  const employeeOasisApiCalls = oasisApiCalls.byShopId?.[params.id]?.oasisApiCallsByEmployeeId?.[params.employeeId] ?? []
  const [, errors] = oasisApiCallResults(employeeOasisApiCalls)
  const sortedErrors = useMemo(
    () => sortBy(errors, error => error.createdAt),
    [errors]
  )

  const user = employees.byId?.[params.employeeId]

  return (
    <WideModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleOasisApiCallErrors', { user: user.email })}
      withForm
    >
      <OasisApiCallErrorsTable items={sortedErrors} />
      <ModalActionButtons
        close={onCancel}
        secondButtonLabel='buttonLabelClose'
      />
    </WideModalScreen>
  )
}

export default OasisApiCallErrorsModal
