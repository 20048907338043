import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import { useShop } from '../shop/hooks'

export const useEmployeeMenuActions = ({ id, handleActivate }) => {
  const navigateTo = useNavigateTo()
  const employee = useEmployee(id)

  return useMemo(
    () => [
      { label: 'resetPassword', onClick: () => navigateTo(privateRoutes.setEmployeePassword, { id }) },
      {
        disabled: !!employee.activatedAt,
        label: employee.activatedAt ? 'sendActivationLinkDisabled' : 'sendActivationLink',
        onClick: () => navigateTo(privateRoutes.sendEmployeeActivationLink, { id })
      },
      {
        disabled: !!employee.activatedAt,
        label: employee.activatedAt ? 'activateUserDisabled' : 'activateUser',
        onClick: () => handleActivate(id)
      }
    ],
    [id, employee.activatedAt]
  )
}

export const useOwnerEmployees = (ownerId) => {
  const [{ employees }] = useBackOffice()

  return useMemo(
    () => employees.ids
      .filter((id) => employees.byId[id].owner === ownerId)
      .sort((idA, idB) => employees.byId[idA].email.localeCompare(employees.byId[idB].email)),
    [ownerId, employees]
  )
}

export const useShopEmployees = (shopId) => {
  const shop = useShop(shopId)
  const employees = useOwnerEmployees(shop.owner)

  return useMemo(
    () => employees.filter((id) => shop.employees.includes(id)),
    [shop.employees, employees]
  )
}

export const useEmployee = (id) => {
  const [{ employees }] = useBackOffice()
  return employees.byId[id]
}

export const useShopAssignableEmployees = (shopId) => {
  const [{ employees }] = useBackOffice()
  const shop = useShop(shopId)
  const ownerEmployees = useOwnerEmployees(shop.owner)
  const assignedEmployees = useShopEmployees(shop.id)

  return useMemo(
    () => ownerEmployees
      .map((id) => ({
        ...employees.byId[id],
        selected: assignedEmployees.includes(id)
      })),
    [ownerEmployees, employees.byId]
  )
}
