import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import logger from '../logger'
import publicRoutes from '../public/routes'

const PrivateRoute = ({ children, ...rest }) => {
  const location = useLocation()
  const { user } = useAuth()

  if (!user) {
    logger.warn(`No logged in user when trying to access '${location?.pathname ?? ''}'`)
    return <Navigate to={publicRoutes.login} replace state={{ from: location }} />
  }

  return (
    <Routes>
      <Route path='*' {...rest} />
    </Routes>
  )
}

export default PrivateRoute
