import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss'
import AuthProvider from './components/AuthProvider'
import OasisErrorBoundary from './components/OasisErrorBoundary/OasisErrorBoundary'
import PrivateRoute from './components/PrivateRoute'
import logger from './logger'
import BackOffice from './private'
import privateRoutes from './private/routes'
import Public from './public'
import publicRoutes from './public/routes'
import theme from './theme'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

const App = () => {
  logger.start()
  const { i18n } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Router>
          <AuthProvider>
            <OasisErrorBoundary>
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
                scriptProps={{ async: true, defer: true, appendTo: 'body' }}
                language={i18n.language}
              >
                <Routes>
                  <Route path={`${privateRoutes.root}/*`} element={<PrivateRoute element={<BackOffice />} />} />
                  <Route
                    path={`${publicRoutes.root}*`}
                    element={<Public />}
                  />
                </Routes>
              </GoogleReCaptchaProvider>
            </OasisErrorBoundary>
          </AuthProvider>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
