import { ApiError, defaultHeaders, defaultOptions } from './const'
import queryString from 'query-string'

const logRequest = (logger) => (response, config) => {
  const message = `${config.method || 'GET'} ${response.url} ${response.status}`
  response.ok ? logger.http(message) : logger.error(message)
  logger.debug(`authorization header: ${!!config.headers?.Authorization}`)
}

const toFormData = (data) => Object.entries(data).reduce(
  (formData, entry) => {
    formData.append(...entry)
    return formData
  },
  new FormData()
)

const apiFetch = (options = {}) => {
  const { baseUrl, logger, session, transformResponse } = { ...defaultOptions, ...options }
  const log = logRequest(logger)

  const fetchFromApi = async (
    path,
    {
      body,
      headers = defaultHeaders,
      method = 'GET'
    } = {}
  ) => {
    if (session?.token) {
      headers.Authorization = `Bearer ${session.token}`
    }

    const response = await fetch(
      `${baseUrl}/${path}`,
      {
        body,
        headers,
        method
      })

    log(response, { method, headers })

    try {
      const json = await response.json()

      if (!response.ok) {
        throw new ApiError(json.error, response.status)
      }

      if (json.token) {
        session?.refresh?.(json.token)
      }

      return transformResponse(json)
    } catch (error) {
      logger.error(error.message)
      throw error
    }
  }

  const deleteReq = (path) => fetchFromApi(
    path,
    { method: 'DELETE' }
  )

  const get = (path) => fetchFromApi(path)

  const post = (path, body = {}) => fetchFromApi(
    path,
    { body: JSON.stringify(body), method: 'POST' }
  )

  const upload = (path, dataToUpload) => {
    return fetchFromApi(
      path,
      { body: toFormData(dataToUpload), method: 'POST', headers: { Accept: defaultHeaders.Accept } }
    )
  }

  const uploadPut = (path, dataToUpload) => {
    return fetchFromApi(
      path,
      { body: toFormData(dataToUpload), method: 'PUT', headers: { Accept: defaultHeaders.Accept } }
    )
  }

  const patch = (path, body) => fetchFromApi(
    path, { body: JSON.stringify(body), method: 'PATCH' }
  )

  return {
    get,
    post,
    upload,
    uploadPut,
    deleteReq,
    patch
  }
}

export default apiFetch

export const getPathnameWithQuery = (path, params) => {
  const query = queryString.stringify(params, { arrayFormat: 'none' })

  return `${path}${query ? '?' + query : ''}`
}
