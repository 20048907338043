import { differenceInMilliseconds, formatDistance } from 'date-fns'
import jwtDecode from 'jwt-decode'

export const getTimeoutDuration = (expiresOn) =>
  differenceInMilliseconds(new Date(expiresOn * 1000), new Date())

export const humanizeExpiresIn = (milliseconds = 0) =>
  formatDistance(0, milliseconds, { includeSeconds: true })

export const getUserFromToken = (token) => {
  try {
    const { id: userId, email: userEmail, role: userRole, features } = jwtDecode(token)
    return { userId, userEmail, userRole, features }
  } catch {
    return null
  }
}
