export const CERTIFICATE_UPLOAD_COMPLETE = 'CERTIFICATE_UPLOAD_COMPLETE'
export const CERTIFICATE_UPDATE_COMPLETE = 'CERTIFICATE_UPDATE_COMPLETE'
export const CREATE_EMPLOYEE_COMPLETE = 'CREATE_EMPLOYEE_COMPLETE'
export const ACTIVATE_EMPLOYEE_COMPLETE = 'ACTIVATE_EMPLOYEE_COMPLETE'
export const CREATE_OWNER_COMPLETE = 'CREATE_OWNER_COMPLETE'
export const DELETE_SHOP_COMPLETE = 'DELETE_SHOP_COMPLETE'
export const CREATE_SHOP_COMPLETE = 'CREATE_SHOP_COMPLETE'
export const EDIT_SHOP_COMPLETE = 'EDIT_SHOP_COMPLETE'
export const ASSIGN_LICENSE_COMPLETE = 'ASSIGN_LICENSE_COMPLETE'
export const ASSIGN_EMPLOYEES_COMPLETE = 'ASSIGN_EMPLOYEES_COMPLETE'
export const FETCH_DISTRIBUTORS = 'FETCH_DISTRIBUTORS'
export const DISTRIBUTOR_DATA_READY = 'DISTRIBUTOR_DATA_READY'
export const FREE_SHOP_LICENSES = 'FREE_SHOP_LICENSES'
export const GENERATE_NEW_LICENSES = 'GENERATE_NEW_LICENSES'
export const CHANGE_DISTRIBUTOR = 'CHANGE_DISTRIBUTOR'
export const LOCK_SHOP_COMPLETE = 'LOCK_SHOP_COMPLETE'
export const DICTIONARY_METADATA_READY = 'DICTIONARY_METADATA_READY'
export const UPDATE_DISTRIBUTOR_FEATURES = 'UPDATE_DISTRIBUTOR_FEATURES'
