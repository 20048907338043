import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import logo from '../../images/logo-blue.png'
import { useTranslation } from 'react-i18next'
import { useDistributor, useDistributorActions } from '../distributor/hooks'
import DistributorSection from './DistributorSection'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    maxWidth: '268px'
  },
  notification: {
    textAlign: 'center',
    color: theme.palette.success.main,
    fontSize: '0.75rem'
  },
  button: {
    width: '100%'
  }
}))

const Header = () => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')
  const distributor = useDistributor()
  const buttons = useDistributorActions(distributor.id)

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='flex-start'
    >
      <Grid item>
        <img className={classes.logo} src={logo} alt='logo' />
      </Grid>
      <Grid item>
        <Box
          display='flex'
          justifyContent='flex-end'
        >
          {buttons.map((button, index) => (
            <Box key={button.label} ml={!index ? 0 : 2}>
              <Button
                onClick={button.handleOnClick}
                color='primary'
                variant='outlined'
                className={classes.button}
              >
                {t(`navbar.${button.label}`)}
              </Button>
              {!!button.notification && (
                <Box mt={0.5} display='flex' justifyContent='center'>
                  <Typography className={classes.notification}>
                    {t(`navbar.${button.notification}`, { numUnassignedLicences: button.unassignedLicensesCount })}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
          <DistributorSection />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Header
