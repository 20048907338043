import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useEmployee } from '../../components/employee/hooks'
import ModalActionButtons from '../../components/modals/modalComponents/ModalActionButtons'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  white-space: nowrap;
`

const InfoBox = styled(Box)`
  padding-block-end: 2rem;
`

const SendEmployeeActivationLink = ({ onCancel, onSubmit }) => {
  const [sending, setSending] = useState(false)
  const { id } = useParams()
  const employee = useEmployee(id)
  const { t } = useTranslation('oasisBackoffice')

  const title = (
    <StyledBox>
      {t('actionDialogs.titleSendEmployeeActivationLink')}
      <Box
        component='span'
        fontWeight='fontWeightBold'
      >
        {employee.email}
      </Box>
    </StyledBox>
  )

  const handleOnSubmit = async () => {
    setSending(true)
    try {
      await onSubmit()
      setSending(false)
    } catch {
      setSending(false)
    }
  }

  // TODO: cleanup the modals
  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={title}
      // we need withForm to hide default modal buttons
      withForm
    >
      <InfoBox>
        {t('actionDialogs.informationSendEmployeeActivationLink')}
      </InfoBox>
      <ModalActionButtons
        isActionInProgress={sending}
        action={handleOnSubmit}
        close={onCancel}
        firstButtonLabel='buttonLabelSend'
        secondButtonLabel='buttonLabelCancel'
      />
    </ModalScreen>
  )
}

SendEmployeeActivationLink.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

SendEmployeeActivationLink.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {}
}

export default SendEmployeeActivationLink
