import PropTypes from 'prop-types'
import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import useNavigateTo from '../hooks/useNavigateTo'
import privateRoutes from './routes'
import useErrorHandler from '../hooks/useErrorHandler'
import { isConflictErrorCode } from '../helpers'

const ModalRoute = ({ component: Component, cancellable, redirectTo, onSubmit, instantRedirect, ...props }) => {
  const navigateTo = useNavigateTo()
  const handleError = useErrorHandler()
  const handleRedirect = () => navigateTo(redirectTo || privateRoutes.root)

  const { id } = useParams()

  const handleOnCancel = () => cancellable && handleRedirect()

  const handleOnSubmit = async (...args) => {
    try {
      if (instantRedirect) {
        handleRedirect()
        await onSubmit(id, ...args)
      } else {
        await onSubmit(id, ...args)
        handleRedirect()
      }
    } catch (e) {
      if (!isConflictErrorCode(e.status)) {
        console.log(e)
        handleError(e)
      } else {
        throw e
      }
    }
  }
  return (
    <Routes>
      <Route
        {...props}
        element={
          <Component
            onCancel={handleOnCancel}
            onSubmit={handleOnSubmit}
          />
        }
      />
    </Routes>
  )
}

ModalRoute.propTypes = {
  ...Route.propTypes,
  cancellable: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  component: PropTypes.elementType.isRequired
}

ModalRoute.defaultProps = {
  cancellable: true
}

export default ModalRoute
