import useAuth from '../../hooks/useAuth'
import { AvailableFeatures, UserRoles } from '../../private/const'
import PropTypes from 'prop-types'
import { isFeatureEnabled } from '../../helpers'

const AuthorizedView = ({ children, features }) => {
  const { user } = useAuth()

  const isAdmin = user.userRole === UserRoles.Admin
  const isAuthorized = isAdmin || (features && isFeatureEnabled(user.features, features))

  return isAuthorized
    ? children
    : null
}

export default AuthorizedView

AuthorizedView.propTypes = {
  children: PropTypes.node,
  features: PropTypes.arrayOf(PropTypes.oneOf(Object.values(AvailableFeatures)))
}
