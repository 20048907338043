import { merge } from 'lodash'
import config from './config.index'

export default merge(
  {},
  config,
  {
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: false,
      useSuspense: false
    }
  }
)
