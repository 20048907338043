import { Grid } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import { useStyles, useRowStyles } from './styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { formatValidUntil } from '../../../helpers'
import { useTranslation } from 'react-i18next'

const AssignLicensesTable = ({ licenses, onSelect, selected }) => {
  const classes = useStyles()
  const rowClasses = useRowStyles()
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <TableContainer component={Paper} elevation={2} className={classes.table}>
          <Table
            size='small'
            aria-label='simple table'
          >
            <colgroup>
              <col style={{ width: '5%' }} />
              <col style={{ width: '60%' }} />
              <col style={{ width: '35%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('modals.assignLicensesTableID')}</TableCell>
                <TableCell>{t('common.expirationDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenses.map((license) => (
                <TableRow key={license.id} hover classes={rowClasses}>
                  <TableCell component='th' scope='row'>
                    <Checkbox
                      id={license.id}
                      color='primary'
                      disabled={license.used}
                      checked={selected[license.id]}
                      onChange={() => onSelect(license.id)}
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>{license.licenseNumber}</TableCell>
                  <TableCell className={classes.cell}>{formatValidUntil(license.validUntil)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default AssignLicensesTable
