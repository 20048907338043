import PropTypes from 'prop-types'
import React from 'react'
import useAccordionToggle from '../../hooks/useAccordionToggle'
import Owner from './Owner'

const OwnersList = ({ owners }) => {
  const [active, toggleActive] = useAccordionToggle(owners[0])

  return (
    <>
      {owners.map((id) =>
        <Owner
          key={id}
          id={id}
          expanded={active === id}
          onClick={toggleActive}
        />)}
    </>
  )
}

OwnersList.propTypes = {
  owners: PropTypes.any,
  actions: PropTypes.any
}

export default OwnersList
