import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import LanguageSelector from '../components/LanguageSelector'
import backgroundImage from '../images/bg-oasis.png'
import logo from '../images/logo-blue.png'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    maxWidth: '268px',
    marginLeft: theme.spacing(1.625),
    marginRight: theme.spacing(1.625)
  },
  paper: {
    padding: theme.spacing(2)
  },
  paperBox: {
    padding: `${theme.spacing(6)}px ${theme.spacing(24)}px`
  },
  mainContainer: {
    minHeight: '100%',
    background: `url('${backgroundImage}') no-repeat center fixed`,
    backgroundSize: 'cover'
  }
}))

const Layout = ({ children }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod'

  return (
    <Grid
      container
      item
      className={classes.mainContainer}
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Paper className={classes.paper}>
        <Box className={classes.paperBox}>
          <Box
            mt={4}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <img className={classes.logo} src={logo} alt='logo' />
          </Box>
          <Box
            mt={8}
            mb={4}
            display='flex'
            justifyContent='center'
          >
            <Box
              display='flex'
              maxWidth={420}
              flexDirection='column'
            >
              {children}
            </Box>
          </Box>
          {!isProduction &&
            <Box
              mt={6}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Typography variant='h5' color='error'>{t('testEnvironment')}</Typography>
            </Box>}
        </Box>
        <LanguageSelector />
      </Paper>
    </Grid>
  )
}

export default Layout
