import logger from '../consoleLogger'

export const defaultOptions = {
  logger,
  autoStart: false,
  storage: localStorage,
  storageKey: 'JWT_TOKEN',
  expirationWarningIn: 30_000
}

export const EVENT_NAMES = {
  EXPIRED: 'jwtSession:token-expired',
  EXPIRATION_WARNING: 'jwtSession:token-expiration-warning',
  REFRESH: 'jwtSession:token-refreshed'
}
