import { Grid } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { formatLastLogin } from '../../../helpers'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '50vh'
  },
  cell: {
    fontSize: '16px'
  }
})

const AssignEmployeesTable = ({ items, onSelect, selected }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')
  const translate = (value) => t(`employee.${value}`)

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <TableContainer
          component={Paper}
          elevation={2}
          className={classes.tableContainer}
        >
          <Table
            size='small'
            aria-label='simple table'
          >
            <colgroup>
              <col style={{ width: '5%' }} />
              <col style={{ width: '60%' }} />
              <col style={{ width: '35%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('employee.headerEmail')}</TableCell>
                <TableCell>{t('employee.headerLastLogin')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Checkbox
                      id={item.id}
                      color='primary'
                      disabled={item.used}
                      checked={selected[item.id]}
                      onChange={() => onSelect(item.id)}
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>{item.email}</TableCell>
                  <TableCell className={classes.cell}>{formatLastLogin(item.lastLoginAt, translate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default AssignEmployeesTable
