import { Helmet } from 'react-helmet'
import Button from '@material-ui/core/Button'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { confirmPasswordInput, passwordInput, reCaptchaField } from '../components/OasisForm/fields'
import { fieldsToForm } from '../components/OasisForm/helpers'
import OasisForm from '../components/OasisForm/OasisForm'
import useAuth from '../hooks/useAuth'
import logger from '../logger'
import publicRoutes from './routes'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { reCaptchaActions } from '../services/api/const'

const fields = {
  password: { ...passwordInput },
  confirmPassword: confirmPasswordInput,
  reCaptcha: { ...reCaptchaField, action: reCaptchaActions.resetPassword }
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4)
  },
  text: {
    marginTop: theme.spacing(2)
  }
}))

const defaultValue = fieldsToForm(fields)

const ResetPassword = () => {
  const classes = useStyles()
  const auth = useAuth()
  const [sent, setSent] = useState(false)
  const { t } = useTranslation('oasisBackoffice')

  const { token } = queryString.parse(location.search)
  const handleOnSubmit = async (formData, setErrors) => {
    try {
      await auth.resetPassword({ ...formData, token })
      setSent(true)
    } catch ({ error, message }) {
      logger.error(`${error.message}: ${JSON.stringify(error)}`)
      setErrors({ email: error.email || 'Are you sure you have an account?' })
    }
  }

  if (!token) {
    logger.warn('No reset password token found.')
    return <Navigate to={publicRoutes.login} replace />
  }

  return (
    <>
      <Helmet>
        <meta name='referrer' content='no-referrer' />
      </Helmet>
      {
        !sent
          ? (
            <OasisForm
              fields={fields}
              defaultValue={defaultValue}
              buttonLabel='buttonLabelSetNewPassword'
              onSubmit={handleOnSubmit}
            />
            )
          : (
            <>
              <Typography variant='h5'>
                {t('resetPassword.info0')}
              </Typography>
              <Typography
                className={classes.text}
                align='center'
                variant='body1'
              >
                {t('resetPassword.info1')}
              </Typography>
              <Button
                className={classes.button}
                color='primary'
                variant='contained'
                component={Link}
                to={publicRoutes.login}
              >
                {t('resetPassword.buttonLabelBackToLoginPage')}
              </Button>
            </>
            )
      }
    </>
  )
}

export default ResetPassword
