import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ModalRoute from '../../private/ModalRoute'
import AssignLicensesToOwner from '../modals/AssignLicenses/AssignLicensesToOwner'
import DeleteShop from '../../private/actionDialogs/DeleteShop'
import CreateShop from '../../private/actionDialogs/CreateShop'
import CreateEmployee from '../../private/actionDialogs/CreateEmployee'

const OwnerRoutes = ({ handleAssignLicensesToOwner, handleCreateEmployee, handleDeleteShop, handleCreateShop }) => {
  return (
    <Routes>
      <Route
        path='*'
        element={
          <>
            <ModalRoute
              path='assign-licenses'
              onSubmit={handleAssignLicensesToOwner}
              component={AssignLicensesToOwner}
            />
            <ModalRoute
              path='delete-shop'
              onSubmit={handleDeleteShop}
              component={DeleteShop}
            />
            <ModalRoute
              path='create-shop'
              onSubmit={handleCreateShop}
              component={CreateShop}
            />

            <ModalRoute
              path='create-employee'
              onSubmit={handleCreateEmployee}
              component={CreateEmployee}
            />
          </>
        }
      />
    </Routes>
  )
}

export default OwnerRoutes
