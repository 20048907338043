import Events from 'events'
import jwtDecode from 'jwt-decode'
import { defaultOptions, EVENT_NAMES } from './const'
import { getTimeoutDuration, getUserFromToken, humanizeExpiresIn } from './helpers'

const jwtSession = (options = {}) => {
  const {
    autoStart,
    expirationWarningIn,
    logger,
    storage,
    storageKey
  } = { ...defaultOptions, ...options }
  const events = new Events()

  let timeout = null

  const getToken = () => storage.getItem(storageKey)

  const setToken = (value) => value
    ? storage.setItem(storageKey, value)
    : storage.removeItem(storageKey)

  const refresh = (jwtToken) => {
    if (jwtToken === getToken() || !timeout) {
      return
    }
    stop()
    start(jwtToken)
    events.emit(EVENT_NAMES.REFRESH, jwtToken)
  }

  const clear = () => {
    logger?.info('Session timer cleared, all event listeners removed, all timers stopped')
    stop()
    events.removeAllListeners()
  }

  const startWarningTimer = (duration, expirationWarningIn) => {
    logger?.info(`Session expires in ${humanizeExpiresIn(duration)}`)
    timeout = setTimeout(
      () => startExpirationTimer(expirationWarningIn),
      duration - expirationWarningIn
    )
  }

  const startExpirationTimer = (duration) => {
    logger?.warn(`Session expires in ${humanizeExpiresIn(duration)}`)
    events.emit(EVENT_NAMES.EXPIRATION_WARNING, duration)
    timeout = setTimeout(
      handleSessionExpired,
      duration
    )
  }

  const handleSessionExpired = () => {
    stop()
    logger?.warn('Session expired')
    events.emit(EVENT_NAMES.EXPIRED)
  }

  const start = (jwtToken) => {
    try {
      const { exp } = jwtDecode(jwtToken)
      const expiresIn = getTimeoutDuration(exp)
      if (expiresIn >= 0) {
        setToken(jwtToken)
        if (expiresIn - expirationWarningIn < 0) {
          startExpirationTimer(expiresIn)
        } else {
          startWarningTimer(expiresIn, expirationWarningIn)
        }
      } else {
        logger?.error('Token already expired')
        stop()
      }
    } catch ({ message }) {
      logger?.error('Failed to decode JWT token')
      stop()
    }
  }

  const stop = () => {
    setToken(null)
    clearTimeout(timeout)
  }

  if (autoStart && getToken()) {
    logger?.info('Starting new session with existing token')
    start(getToken())
  }

  return {
    get token () { return getToken() },
    get user () { return getUserFromToken(getToken()) },
    clear,
    refresh,
    start,
    stop,
    onExpire: (callback) => events.on(EVENT_NAMES.EXPIRED, callback),
    onExpireWarning: (callback) => events.on(EVENT_NAMES.EXPIRATION_WARNING, callback),
    onRefresh: (callback) => events.on(EVENT_NAMES.REFRESH, callback)
  }
}

export default jwtSession
