import { merge } from 'lodash'
import { initReactI18next } from 'react-i18next'
import i18n from './i18n'
import reactConfig from './config.react'

const initFn = (opts = {}) => i18n
  .use(initReactI18next)
  .init(merge({}, reactConfig, opts))

export default initFn
