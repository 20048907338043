import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import EmptyView from '../common/EmptyView'
import SearchField from '../common/SearchField'
import { useFilteredOwners } from '../owner/hooks'
import OwnersList from '../owner/OwnersList'
import { useDistributor } from './hooks'
import GroupIcon from '@material-ui/icons/Group'
import Footer from '../Footer/Footer'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import NoOwnersView from '../common/NoOwnersView'

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'hidden',
    width: '100%',
    margin: 0
  },
  searchFieldContainer: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  content: {
    overflowY: 'auto',
    height: '100%',
    display: 'flex'
  },
  ownersContainer: {
    flex: 1,
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  }
}))

const DistributorContent = () => {
  const [search, setSearch] = useState('')
  const distributor = useDistributor()
  const owners = useFilteredOwners(distributor.id, '')
  const filteredOwners = useFilteredOwners(distributor.id, search)
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  const searchField = !!owners.length &&
    <SearchField
      label={t('distributor.searchFieldLabel')}
      value={search}
      onChange={setSearch}
    />
  const noOwnersView = !owners.length && <NoOwnersView distributorId={distributor.id} />
  const ownersListView = !!owners.length && <OwnersList owners={filteredOwners} />
  const filteredOwnersView = !filteredOwners.length && !!search && <EmptyView text={t('distributor.noOwnersFound')} icon={GroupIcon} />

  return (
    <Grid
      wrap='nowrap'
      component={Box}
      height='100%'
      container
      direction='column'
      className={classes.container}
    >
      <Grid
        item
        className={classes.searchFieldContainer}
      >
        {searchField}
      </Grid>
      <Grid
        item
        container
        direction='column'
        wrap='nowrap'
        className={classes.content}
      >
        <Grid
          item
          className={classes.ownersContainer}
        >
          {noOwnersView}
          {ownersListView}
          {filteredOwnersView}
        </Grid>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default DistributorContent
