import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { makeStyles } from '@material-ui/core/styles'
import { PersonOutlineOutlined } from '@material-ui/icons'
import React from 'react'
import AccordionCreateShop from '../common/AccordionCreateShop'
import AccordionSummaryWithActions from '../common/AccordionSummaryWithActions'
import { useCreateShop, useSortedShops } from '../shop/hooks'
import ShopsList from '../shop/ShopsList'
import { useOwner, useOwnerMenuActions } from './hooks'
import { useBackOffice } from '../../private/hooks/useBackOffice'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent'
  },
  details: {
    flexDirection: 'column',
    padding: 0,
    margin: theme.spacing(2)
  },
  shopsList: {
    flex: 1
  }
}))

const Owner = ({ id, expanded, onClick }) => {
  const classes = useStyles()
  const owner = useOwner(id)
  const shops = useSortedShops(id)
  const [{ scans, oasisApiCalls }] = useBackOffice()

  const menuActions = useOwnerMenuActions(id)

  const handleOnCreateShop = useCreateShop(id)

  const handleOnClick = () => onClick(id)

  const ownerScanCount = scans.byOwnerId[id]?.length
  const ownerOasisApiCalls = oasisApiCalls.byOwnerId[id]

  return (
    <Accordion
      className={classes.root}
      expanded={expanded}
      onChange={handleOnClick}
      elevation={0}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummaryWithActions
        header={owner.name}
        actions={menuActions}
        scanCount={ownerScanCount}
        oasisApiCalls={ownerOasisApiCalls}
      >
        <PersonOutlineOutlined fontSize='medium' color='disabled' />
      </AccordionSummaryWithActions>
      <AccordionDetails
        className={classes.details}
      >
        {shops.length > 0 && <ShopsList className={classes.shopsList} shops={shops} />}
        <AccordionCreateShop onClick={handleOnCreateShop} />
      </AccordionDetails>
    </Accordion>
  )
}

export default Owner
