import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'

const EmptyView = ({ icon: Icon, text }) =>
  <Box
    height='80%'
    display='flex'
    justifyContent='center'
    alignItems='center'
    flexDirection='column'
    gridGap={4}
  >
    <Icon style={{ width: 128, height: 128 }} color='disabled' />
    <Typography variant='h5'>{text}</Typography>
  </Box>

EmptyView.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired
}

export default EmptyView
