import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'
import { useEffect } from 'react'
import OasisErrorPage from './OasisErrorPage'

const OasisErrorBoundary = ({ children }) => (
  <ErrorBoundary FallbackComponent={OasisErrorPage}>
    <OasisUnhandledError>
      {children}
    </OasisUnhandledError>
  </ErrorBoundary>
)

const OasisUnhandledError = ({ children }) => {
  const handleError = useErrorHandler()

  useEffect(() => {
    const handleUnhandledException = (event) => {
      event.preventDefault()
      event.stopImmediatePropagation()
      // recaptcha v2 doesn't like to be unmounted by React and throws a Timeout
      // if we don't skip this here we get a random error page after using recaptcha
      if (event.reason === 'Timeout' && event instanceof PromiseRejectionEvent) {
        return
      }
      handleError(event.reason)
    }

    window.addEventListener('unhandledrejection', handleUnhandledException)

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledException)
    }
  }, [handleError])

  return <>{children}</>
}

export default OasisErrorBoundary
