import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Route, Routes } from 'react-router-dom'
import logger from '../../logger'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import Layout from '../../private/Layout'
import privateRoutes from '../../private/routes'
import {
  ASSIGN_EMPLOYEES_COMPLETE,
  ASSIGN_LICENSE_COMPLETE,
  CERTIFICATE_UPLOAD_COMPLETE,
  CREATE_EMPLOYEE_COMPLETE,
  CREATE_OWNER_COMPLETE,
  CREATE_SHOP_COMPLETE,
  DELETE_SHOP_COMPLETE,
  EDIT_SHOP_COMPLETE,
  DISTRIBUTOR_DATA_READY,
  CERTIFICATE_UPDATE_COMPLETE,
  FREE_SHOP_LICENSES,
  GENERATE_NEW_LICENSES,
  LOCK_SHOP_COMPLETE,
  DICTIONARY_METADATA_READY,
  UPDATE_DISTRIBUTOR_FEATURES
} from '../../reducer/actions'
import { api } from '../../services/api'
import { ApiError } from '../../services/api/const'
import Loading from '../Loading'
import DistributorContent from './DistributorContent'
import { useDistributor } from './hooks'
import { useSnackbar } from 'notistack'
import Header from '../Header/Header'
import DistributorRoutes from './DistributorRoutes'
import OwnerRoutes from './OwnerRoutes'
import ShopRoutes from './ShopRoutes'
import EmployeeRoutes from './EmployeeRoutes'
import ModalRoute from '../../private/ModalRoute'
import UploadDistributorCertificate from '../../private/actionDialogs/UploadDistributorCertificate'
import CreateOwner from '../../private/actionDialogs/CreateOwner'
import useNavigateTo from '../../hooks/useNavigateTo'
import useAuth from '../../hooks/useAuth'
import { AvailableFeatures, UserFeatures, UserRoles } from '../../private/const'
import { startOfMonth } from 'date-fns'
import { isFeatureActivated } from '../../private/featureHelpers'
import { isFeatureEnabled } from '../../helpers'

const {
  assignEmployeesToShop,
  assignLicensesToOwner,
  assignLicensesToShop,
  createEmployee,
  createOwner,
  createShop,
  editShopCredentials,
  deleteShop,
  editShop,
  fetchDistributorEmployees,
  fetchDistributorLicenses,
  fetchDistributorOwners,
  fetchDistributorShops,
  fetchDistributorScans,
  setEmployeePassword,
  sendEmployeeActivationLink,
  uploadCertificate,
  updateCertificate,
  updateUserFeature,
  freeShopLicenses,
  generateNewLicenses,
  lockShop,
  fetchDictionaryMetaData,
  fetchDistributorSchedules,
  fetchDistributorOasisApiCalls
} = api

const Distributor = ({ id }) => {
  const [, dispatch] = useBackOffice()
  const distributor = useDistributor()
  const { t } = useTranslation('oasisBackoffice')
  const { enqueueSnackbar } = useSnackbar()
  const navigateTo = useNavigateTo()
  const {
    user
  } = useAuth()

  const [ready, setReady] = useState(false)

  useLayoutEffect(() => {
    setReady(false)
  }, [id])

  const isAdmin = user.userRole === UserRoles.Admin

  useEffect(() => {
    const fetchData = async () => {
      const commonPromises = [
        fetchDistributorShops(id),
        fetchDistributorOwners(id),
        fetchDistributorLicenses(id),
        fetchDistributorEmployees(id),
        fetchDictionaryMetaData(),
        fetchDistributorSchedules(id)
      ]

      if (isAdmin || isFeatureEnabled(user.features, [AvailableFeatures.oasisApiCounting])) {
        const date = new Date()
        const params = {
          startDate: startOfMonth(date).toISOString()
        }

        commonPromises.push(
          fetchDistributorOasisApiCalls(id, params)
        )

        if (isAdmin) {
          commonPromises.push(
            fetchDistributorScans(id, params)
          )
        }
      }

      try {
        logger.updateContext({ distributorId: id })
        logger.info('Loading distributor data')

        const [
          shops,
          owners,
          licenses,
          employees,
          metaData = [],
          schedules = [],
          oasisApiCalls = [],
          scans = []
        ] = await Promise.all(commonPromises)

        dispatch({
          type: DISTRIBUTOR_DATA_READY,
          payload: { shops, owners, licenses, employees, scans, schedules, oasisApiCalls }
        })

        dispatch({
          type: DICTIONARY_METADATA_READY,
          payload: metaData
        })

        logger.debug(`shops fetched: ${shops.length}`)
        logger.debug(`owners fetched: ${owners.length}`)
        logger.debug(`licenses fetched: ${licenses.length}`)
        logger.debug(`employees fetched: ${employees.length}`)
        logger.debug(`metadata fetched: ${metaData}`)
        logger.debug(`scans fetched: ${scans.length}`)
        logger.debug(`schedules fetched: ${schedules.length}`)
        logger.debug(`Oasis Api calls fetched: ${schedules.length}`)

        setReady(true)
      } catch (error) {
        logger.error(error.message)
        throw error
      }
    }
    fetchData()
  }, [id, dispatch])

  const hasCertificate = !!distributor?.certificate ?? false

  useEffect(() => {
    !hasCertificate &&
    navigateTo(generatePath(privateRoutes.uploadCertificate, { id }), {
      replace: true
    })
  }, [hasCertificate])

  const handleAssignLicensesToOwner = useCallback(
    async (ownerId, data) => {
      logger.info(`Updating owner license assignment for owner '${ownerId}'`, {
        ownerId
      })
      logger.debug(data)
      dispatch({
        type: ASSIGN_LICENSE_COMPLETE,
        payload: await assignLicensesToOwner(ownerId, data)
      })
    },
    [dispatch]
  )

  const handleAssignLicensesToShop = useCallback(
    async (shopId, data) => {
      logger.info(`Updating shop license assignment for shop '${shopId}'`, {
        shopId
      })
      logger.debug(data)
      dispatch({
        type: ASSIGN_LICENSE_COMPLETE,
        payload: await assignLicensesToShop(shopId, data)
      })
    },
    [dispatch]
  )

  const handleAssignEmployeesToShop = useCallback(
    async (shopId, data) => {
      logger.info(`Updating shop employee assignment for shop '${shopId}'`, {
        shopId
      })
      logger.debug(data)
      dispatch({
        type: ASSIGN_EMPLOYEES_COMPLETE,
        payload: await assignEmployeesToShop(shopId, data)
      })
    },
    [dispatch]
  )

  const handleCreateOwner = useCallback(
    async (distributorId, data) => {
      logger.info(
        `Creating owner '${data.name}' for distributor '${distributorId}'`,
        { distributorId }
      )
      logger.debug(data)
      dispatch({
        type: CREATE_OWNER_COMPLETE,
        payload: await createOwner(distributorId, data)
      })
    },
    [dispatch]
  )

  const handleCreateShop = useCallback(
    async (ownerId, data) => {
      logger.info(`Creating shop '${data.name}' for owner '${ownerId}'`, {
        ownerId
      })
      logger.debug(data)
      try {
        dispatch({
          type: CREATE_SHOP_COMPLETE,
          payload: await createShop(ownerId, data)
        })
      } catch (error) {
        logger.warn(`Create shop for owner '${ownerId}' failed`, {
          distributorId: id
        })
        if (error.error?.certificatePassword) {
          throw new ApiError(
            error.error,
            error.status,
            t(`errors.${error.error.certificatePassword}`)
          )
        }
        throw error
      }
    },
    [dispatch]
  )

  const handleDeleteShop = useCallback(
    async (shopId) => {
      logger.info(`Deleting shop '${shopId}' `, { shopId })
      try {
        dispatch({
          type: DELETE_SHOP_COMPLETE,
          payload: await deleteShop(shopId)
        })
        enqueueSnackbar(t('successInfo.shopDeletion'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(
          `${t('errors.shopDeletionError')} ${error || 'Unknown'}`,
          { variant: 'error' }
        )
      }
    },
    [dispatch]
  )

  const handleFreeLicenses = useCallback(
    async (shopId) => {
      logger.info(`Free licenses in shop '${shopId}' `, { shopId })
      try {
        dispatch({
          type: FREE_SHOP_LICENSES,
          payload: await freeShopLicenses(shopId)
        })
        enqueueSnackbar(t('successInfo.freeLicense'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(
          `${t('errors.freeLicenseError')} ${error || 'Unknown'}`,
          { variant: 'error' }
        )
      }
    },
    [dispatch]
  )

  const handleGenerateNewLicenses = useCallback(
    async (distributorId, data) => {
      logger.info(`Generate new licenses for distributor ${distributorId}' `, {
        distributorId
      })
      try {
        dispatch({
          type: GENERATE_NEW_LICENSES,
          payload: await generateNewLicenses(distributorId, data)
        })
        enqueueSnackbar(t('successInfo.generatedLicenses'), {
          variant: 'success'
        })
      } catch (error) {
        enqueueSnackbar(
          `${t('errors.generatedLicenses')} ${error || 'Unknown'}`,
          { variant: 'error' }
        )
      }
    },
    [dispatch]
  )

  const handleEditShopCredentials = useCallback(async (shopId, data) => {
    logger.info(`Editing shop credentials with id '${shopId}'`, { shopId })
    logger.debug(data)
    try {
      await editShopCredentials(shopId, data)
      enqueueSnackbar(t('successInfo.shopCredentialsChange'), {
        variant: 'success'
      })
    } catch (error) {
      logger.warn(
        `Edit shop credentials with id '${shopId}' failed with ${error}`,
        { shopId }
      )
      if (error.error?.certificatePassword) {
        throw new ApiError(
          error.error,
          error.status,
          t(`errors.${error.error.certificatePassword}`)
        )
      }
      throw error
    }
  }, [])

  const handleEditShop = useCallback(
    async (shopId, data) => {
      logger.info(`Editing shop with id '${shopId}'`, { shopId })
      logger.debug(data)
      dispatch({
        type: EDIT_SHOP_COMPLETE,
        payload: await editShop(shopId, data)
      })
    },
    [dispatch]
  )

  const handleCreateEmployee = useCallback(
    async (ownerId, data) => {
      logger.info(`Creating employee '${data.email}' for owner '${ownerId}'`, {
        ownerId
      })
      logger.debug(data)
      dispatch({
        type: CREATE_EMPLOYEE_COMPLETE,
        payload: await createEmployee(ownerId, data)
      })
    },
    [dispatch]
  )

  const handleSetEmployeePassword = useCallback(async (employeeId, data) => {
    logger.info(`Resetting password for employee '${employeeId}'`, {
      employeeId
    })
    logger.debug(data)
    await setEmployeePassword(employeeId, data)
  }, [])

  const handleSendEmployeeActivationLink = useCallback(async (employeeId) => {
    logger.info(`Sending activation link for employee '${employeeId}'`, {
      employeeId
    })
    await sendEmployeeActivationLink(employeeId)
  }, [])

  const handleUploadCertificate = useCallback(
    async (id, data) => {
      logger.info(`Uploading new certificate for distributor '${id}'`, {
        distributorId: id
      })
      logger.debug(data)
      try {
        dispatch({
          type: CERTIFICATE_UPLOAD_COMPLETE,
          payload: { id, certificate: await uploadCertificate(id, data) }
        })
      } catch (error) {
        logger.warn(`Upload for distributor '${id}' failed`, {
          distributorId: id
        })
        if (error.error?.certificate) {
          throw new ApiError(
            error.error,
            error.status,
            t(`errors.${error.error.certificate}`)
          )
        }
        throw error
      }
    },
    [dispatch]
  )

  const handleUpdateCertificate = useCallback(
    async (id, data) => {
      logger.info(`Updating certificate for distributor '${id}'`, {
        distributorId: id
      })
      logger.debug(data)
      try {
        dispatch({
          type: CERTIFICATE_UPDATE_COMPLETE,
          payload: { id, certificate: await updateCertificate(id, data) }
        })
      } catch (error) {
        logger.warn(`Certificate update for distributor '${id}' failed`, {
          distributorId: id
        })
        if (error.error?.certificate) {
          throw new ApiError(
            error.error,
            error.status,
            t(`errors.${error.error.certificate}`)
          )
        }
        throw error
      }
    },
    [dispatch]
  )

  const handleLockShop = useCallback(
    async (shopId, data) => {
      logger.info(`${data.locked ? 'Lock' : 'Unlock'} shop '${shopId}' `, {
        shopId
      })

      try {
        dispatch({
          type: LOCK_SHOP_COMPLETE,
          payload: await lockShop(shopId, data)
        })
        data.locked
          ? enqueueSnackbar(t('successInfo.shopLock'), { variant: 'success' })
          : enqueueSnackbar(t('successInfo.shopUnlock'), { variant: 'success' })
      } catch (error) {
        data.locked
          ? enqueueSnackbar(
            `${t('errors.lockShopError')} ${error || 'Unknown'}`,
            { variant: 'error' }
          )
          : enqueueSnackbar(
            `${t('errors.unlockShopError')} ${error || 'Unknown'}`,
            { variant: 'error' }
          )
      }
    },
    [dispatch]
  )

  const handleUpdateCountingFunction = useCallback(
    async (userId, data) => {
      logger.info(`Updating features for user with id '${userId}'`, { userId })

      try {
        dispatch({
          type: UPDATE_DISTRIBUTOR_FEATURES,
          payload: await updateUserFeature(userId, data)
        })
        isFeatureActivated(data.features, UserFeatures.OasisApiCounting)
          ? enqueueSnackbar(t('successInfo.featureActivated'), { variant: 'success' })
          : enqueueSnackbar(t('successInfo.featureDeactivated'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(
          `${t('errors.featureUpdateError')} ${error || 'Unknown'}`,
          { variant: 'error' }
        )
      }
    },
    [dispatch]
  )

  return (
    <>
      {ready && hasCertificate
        ? (
          <>
            <Layout header={Header} content={DistributorContent} />
            <Routes>
              <Route
                path='/employee/:id/*'
                element={
                  <EmployeeRoutes
                    handleSetEmployeePassword={handleSetEmployeePassword}
                    handleSendEmployeeActivationLink={
                      handleSendEmployeeActivationLink
                    }
                  />
                }
              />
              <Route
                path='/shop/:id/*'
                element={
                  <ShopRoutes
                    handleAssignLicensesToShop={handleAssignLicensesToShop}
                    handleAssignEmployeesToShop={handleAssignEmployeesToShop}
                    handleFreeLicenses={handleFreeLicenses}
                    handleEditShopCredentials={handleEditShopCredentials}
                    handleEditShop={handleEditShop}
                    handleLockShop={handleLockShop}
                  />
                }
              />
              <Route
                path='/owner/:id/*'
                element={
                  <OwnerRoutes
                    handleAssignLicensesToOwner={handleAssignLicensesToOwner}
                    handleDeleteShop={handleDeleteShop}
                    handleCreateShop={handleCreateShop}
                    handleCreateEmployee={handleCreateEmployee}
                  />
                }
              />
              <Route
                path='/distributor/:id/*'
                element={
                  <DistributorRoutes
                    handleUpdateCertificate={handleUpdateCertificate}
                    handleGenerateNewLicenses={handleGenerateNewLicenses}
                    handleUpdateCountingFunction={handleUpdateCountingFunction}
                  />
                }
              />
            </Routes>
          </>)
        : (
          <Loading />
        )}
      <Routes>
        <Route
          path='/distributor/:id/*'
          element={
            <>
              <ModalRoute
                path='create-owner'
                onSubmit={handleCreateOwner}
                component={CreateOwner}
              />
              <ModalRoute
                path='upload-certificate'
                onSubmit={handleUploadCertificate}
                redirectTo={generatePath(privateRoutes.createOwner, { id })}
                component={UploadDistributorCertificate}
              />
            </>
          }
        />
      </Routes>
    </>
  )
}

Distributor.propTypes = {
  id: PropTypes.string.isRequired
}

export default Distributor
