import { identity, mapValues, pickBy } from 'lodash'
import { isEmail, isLength } from 'validator'

const getMustMatchError = (key) =>
  key === 'confirmPassword'
    ? 'oasisForm.errorPasswordMustMatch'
    : 'oasisForm.errorCertPasswordMustMatch'

// create a formik validation function based on the fields defined in form
// the key is the name of the field that will get passed to the form
// the value is the input props that will be passed to TextField, and they have everything we need for validation
export const getFormikValidateFn = (fields, translateFunction = identity) => (values) => Object.entries(values).reduce(
  (errors, [key, value]) => {
    const { label, match, required, type } = fields[key] || {}

    const typeValidators = {
      text: {
        message: '',
        validate: () => true
      },
      email: {
        message: translateFunction('oasisForm.errorInvalidEmail'),
        validate: isEmail
      },
      password: {
        message: translateFunction('oasisForm.errorLength'),
        validate: () => isLength(value, { min: 4, max: 100 })
      }
    }

    const nameValidators = {
      name: {
        message: translateFunction('oasisForm.errorLength'),
        validate: () => isLength(value, { min: 4, max: 100 })
      },
      passphrase: {
        message: translateFunction('oasisForm.errorPassphraseLength'),
        validate: () => isLength(value, { min: 24, max: 24 })
      },
      confirmPassphrase: {
        message: translateFunction('oasisForm.errorPassphraseLength'),
        validate: () => isLength(value, { min: 24, max: 24 })
      }
    }

    if ((value === undefined || value === '') && required) {
      errors[key] = translateFunction(`oasisForm.${label}`) + ' ' + translateFunction('oasisForm.errorIsRequired')
    } else if (value && !!match && value !== values[match]) {
      errors[key] = translateFunction(getMustMatchError(key))
      errors[match] = translateFunction(getMustMatchError(key))
    } else if (value && nameValidators[key]) {
      const validator = nameValidators[key]
      if (!validator.validate(value)) {
        errors[key] = validator.message
      }
    } else if (value && typeValidators[type]) {
      const validator = typeValidators[type]
      if (!validator.validate(value)) {
        errors[key] = validator.message
      }
    }

    return errors
  },
  {}
)

export const fieldsToForm = (fields, defaults) => Object.freeze(mapValues(fields, (field, key) => defaults?.[key] ?? ''))

export const setPassphraseValue = (value) => {
  value = value.split('-').join('')
  if (value.length > 0) {
    return value.match(/.{1,4}/g).join('-')
  }
}

export const getChangedValues = (values, initialValues) => (
  pickBy(values, (value, key) => value !== initialValues[key])
)
