import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  confirmPasswordInput,
  emailInput,
  nameInput,
  passwordInput,
  reCaptchaField
} from '../components/OasisForm/fields'
import { fieldsToForm } from '../components/OasisForm/helpers'
import OasisForm from '../components/OasisForm/OasisForm'
import { isConflictErrorCode } from '../helpers'
import useAuth from '../hooks/useAuth'
import logger from '../logger'
import publicRoutes from './routes'
import { useTranslation } from 'react-i18next'
import { reCaptchaActions } from '../services/api/const'

const useStyles = makeStyles(theme => ({
  forgottenPasswordLink: {
    textDecoration: 'underline',
    color: theme.palette.primary.main
  }
}))

const fields = {
  name: {
    ...nameInput,
    label: 'fieldLabelDistributorName',
    autoComplete: 'organization',
    required: true
  },
  email: {
    ...emailInput,
    autoComplete: 'email',
    required: true
  },
  password: passwordInput,
  confirmPassword: confirmPasswordInput,
  reCaptcha: { ...reCaptchaField, action: reCaptchaActions.register }
}

const emptyForm = fieldsToForm(fields)

const SignUp = () => {
  const classes = useStyles()
  const auth = useAuth()
  const { t } = useTranslation('oasisBackoffice')

  const handleOnSubmit = async (formData, setErrors) => {
    try {
      logger.info('Signing up new user')
      await auth.signUp(formData)
    } catch ({ error, message, status }) {
      logger.error(`${message}: ${JSON.stringify(error)}`)
      if (isConflictErrorCode(status)) {
        setErrors({
          email: error.email
            ? (
              <span>
                {t('signUp.errorEmailInUse')}
                <Link
                  className={classes.forgottenPasswordLink}
                  to={publicRoutes.forgotPassword}
                >
                  {t('signUp.errorForgottenPassword')}
                </Link>
              </span>)
            : undefined
        })
      } else {
        setErrors(error)
      }
    }
  }
  return (
    <OasisForm
      fields={fields}
      defaultValue={emptyForm}
      buttonLabel='buttonLabelSignUp'
      backHref='/login'
      onSubmit={handleOnSubmit}
    />
  )
}

export default SignUp
