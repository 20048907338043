import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { emailInput, reCaptchaField } from '../components/OasisForm/fields'
import { fieldsToForm } from '../components/OasisForm/helpers'
import OasisForm from '../components/OasisForm/OasisForm'
import useAuth from '../hooks/useAuth'
import logger from '../logger'
import publicRoutes from './routes'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { reCaptchaActions } from '../services/api/const'

const fields = {
  email: emailInput,
  reCaptcha: { ...reCaptchaField, action: reCaptchaActions.forgotPassword }
}

const defaultValue = fieldsToForm(fields)

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4)
  },
  header: {
    marginBottom: theme.spacing(2)
  }
}))

const ForgotPassword = () => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  const auth = useAuth()
  const [sent, setSent] = useState(false)
  const handleOnSubmit = async (formData, setErrors) => {
    try {
      await auth.forgotPassword(formData)
      setSent(true)
    } catch (error) {
      logger.error(`${error.message}: ${JSON.stringify(error.error)}`)
      setErrors({ email: t('forgotPassword.error') })
    }
  }

  return (
    <>
      {
        !sent
          ? (
            <OasisForm
              defaultValue={defaultValue}
              fields={fields}
              buttonLabel='buttonLabelResetPassword'
              backHref={publicRoutes.login}
              onSubmit={handleOnSubmit}
            />
            )
          : (
            <>
              <Typography
                className={classes.header}
                variant='h5'
                align='center'
              >
                {t('forgotPassword.info0')}
              </Typography>
              <Typography variant='body1'>
                {t('forgotPassword.info1')}
                <br />
                <br />
                {t('forgotPassword.info2')}
              </Typography>
              <Button
                className={classes.button}
                color='primary'
                variant='contained'
                component={Link}
                to={publicRoutes.login}
              >
                {t('forgotPassword.buttonLabelBackToLoginPage')}
              </Button>
            </>
            )
      }
    </>
  )
}

export default ForgotPassword
