import { useCallback, useEffect, useMemo, useReducer } from 'react'
import logger from '../logger'
import { api, authApi } from '../services/api'
import session from '../services/session'
import publicRoutes from '../public/routes'
import { useNavigate } from 'react-router-dom'

const useAuthProvider = ({
  onLogin,
  onLogout,
  onSessionExpired,
  onSessionRefresh,
  onSessionWarning
}) => {
  const [{ user, expires }, updateState] = useReducer(
    (state, data) => ({ ...state, ...data }),
    { user: session.user, expires: null }
  )

  const navigate = useNavigate()

  const handleLogin = useCallback(
    (token) => {
      logger.info('Logged in')
      session.start(token)
      updateState({ user: session.user })
      onLogin?.()
    },
    [updateState]
  )

  const handleLogout = useCallback(
    () => {
      logger.info('Logging out')
      session.stop()
      updateState({ expires: null, user: null })
      onLogout?.()
      navigate(publicRoutes.login)
    },
    [updateState]
  )

  const login = useCallback(
    async (formData) => {
      try {
        logger.info(`Logging in '${formData.email}'`)
        const { token } = await authApi.login(formData)
        handleLogin(token)
      } catch (error) {
        logger.error('Login failed')
        handleLogout()
        throw error
      }
    },
    [handleLogin, handleLogout]
  )

  const forgotPassword = useCallback(
    async (formData) => {
      try {
        logger.info(`Sending forgot password request for ${formData.email}'`)
        await authApi.forgotPassword(formData)
      } catch (error) {
        logger.error('Forgot password failed')
        throw error
      }
    },
    [authApi]
  )

  const resetPassword = useCallback(
    async (formData) => {
      try {
        logger.info(`Resetting password using '${formData.token}`)
        await authApi.resetPassword(formData)
      } catch (error) {
        logger.error('Failed to reset password')
        throw error
      }
    },
    [authApi]
  )

  const signUp = useCallback(
    async (formData) => {
      try {
        logger.info(`Signing up '${formData.name}'`)
        const { token } = await authApi.signUp(formData)
        handleLogin(token)
      } catch (error) {
        logger.error('Failed to sign up')
        throw error
      }
    },
    [authApi, handleLogin]
  )

  const keepAlive = useCallback(
    async () => {
      await api.keepAlive()
    },
    [api]
  )

  useEffect(
    () => {
      try {
        if (user) {
          logger.updateContext(user)
        } else {
          logger.setContext({})
        }
      } catch (error) {
        logger.error('Failed to decode session token')
        logger.setContext({})
      }
    },
    [user]
  )

  useEffect(
    () => {
      session.onExpireWarning((expires) => {
        updateState({ expires })
        onSessionWarning?.(keepAlive)
      })

      session.onExpire(() => {
        updateState({ user: null, expires: null })
        onSessionExpired?.()
      })

      session.onRefresh(() => {
        if (expires) {
          updateState({ expires: null })
          onSessionRefresh?.()
        }
      })

      return () => {
        session.clear()
      }
    },
    [keepAlive, onSessionExpired, onSessionRefresh, onSessionWarning, session, updateState]
  )

  return useMemo(
    () => ({
      expires,
      user,
      login,
      logout: handleLogout,
      signUp,
      forgotPassword,
      keepAlive,
      resetPassword
    }),
    [expires, user, forgotPassword, keepAlive, resetPassword, login, handleLogout]
  )
}

export default useAuthProvider
