import { Navigate, Route, Routes } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import privateRoutes from '../private/routes'
import publicRoutes from './routes'
import Layout from './Layout'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import SignUp from './SignUp'
import ResetPassword from './ResetPassword'

const Public = () => {
  const { user } = useAuth()

  if (user) {
    return <Navigate to={privateRoutes.root} replace />
  }

  return (
    <Layout>
      <Routes>
        <Route path={publicRoutes.login} element={<Login />} />
        <Route path={publicRoutes.signUp} element={<SignUp />} />
        <Route path={publicRoutes.forgotPassword} element={<ForgotPassword />} />
        <Route path={publicRoutes.resetPassword} element={<ResetPassword />} />
        <Route path='*' element={<Navigate to={publicRoutes.login} replace />} />
      </Routes>
    </Layout>
  )
}

export default Public
