import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AuthorizedView from '../common/AuthorizedView'
import { AvailableFeatures } from '../../private/const'

const EmployeeHeader = () => {
  const { t } = useTranslation('oasisBackoffice')

  return (
    <TableRow>
      <TableCell width='62px' />
      <TableCell>{t('employee.headerEmail')}</TableCell>
      <AuthorizedView>
        <TableCell width='160px'>{t('common.scanCount')}</TableCell>
      </AuthorizedView>
      <AuthorizedView features={[AvailableFeatures.oasisApiCounting]}>
        <TableCell width='160px' align='center'>{t('common.oasisApiCalls')}</TableCell>
      </AuthorizedView>
      <TableCell width='160px'>{t('employee.headerLastLogin')}</TableCell>
      <TableCell width='160px'>{t('employee.headerActivatedAt')}</TableCell>
      <TableCell width='120px' />
    </TableRow>
  )
}
export default EmployeeHeader
