import { sortBy } from 'lodash'
import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import { useAssignLicenses, useOwnerAssignableLicenses } from '../license/hooks'
import { useCreateShop } from '../shop/hooks'

export const useOwnerMenuActions = (id) => {
  const navigateTo = useNavigateTo()
  const createShop = useCreateShop(id)
  const licenses = useOwnerAssignableLicenses(id)
  const assignLicensesAction = useAssignLicenses(licenses, () => navigateTo(privateRoutes.assignLicensesToOwner, { id }))

  return useMemo(
    () => [
      {
        label: 'addEmployee',
        onClick: () => navigateTo(privateRoutes.createEmployee, { id })
      },
      assignLicensesAction,
      {
        label: 'createShop',
        onClick: createShop
      }
    ],
    [id, licenses, navigateTo]
  )
}

export const useOwners = (distributorId) => {
  const [{ owners: { ids = [], byId } }] = useBackOffice()

  return useMemo(
    () => ids.filter((id) => byId[id].distributor === distributorId),
    [distributorId, ids, byId]
  )
}

export const useSortedOwners = (distributorId) => {
  const ownerIds = useOwners(distributorId)
  const [{ owners: { byId } }] = useBackOffice()

  return useMemo(
    () => sortBy(ownerIds, (id) => byId[id].name.toLocaleLowerCase()),
    [distributorId, ownerIds, byId]
  )
}

export const useFilteredOwners = (distributorId, search) => {
  const sortedOwners = useSortedOwners(distributorId)
  const [{ owners: { byId } }] = useBackOffice()

  return useMemo(
    () => sortedOwners.filter((id) => byId[id].name.toLocaleLowerCase().includes(search.toLocaleLowerCase())),
    [sortedOwners, byId, search]
  )
}

export const useOwner = (id) => {
  const [{ owners }] = useBackOffice()

  return owners.byId[id]
}
