import Typography from '@material-ui/core/Typography'
import React from 'react'
import { makeStyles, Accordion, Box } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  typography: {
    alignSelf: 'center',
    color: theme.palette.primary.main
  },
  box: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer'
  }
}))

const AccordionCreateShop = ({ onClick }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Accordion>
      <Box
        display='flex'
        className={classes.box}
        onClick={() => onClick()}
      >
        <IconButton
          size='medium'
          color='primary'
          onFocus={(e) => e.stopPropagation()}
          aria-label='create shop button'
        >
          <AddCircleIcon />
        </IconButton>
        <Typography className={classes.typography}>
          {t('common.createNewShop')}
        </Typography>
      </Box>
    </Accordion>
  )
}

export default AccordionCreateShop
