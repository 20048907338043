import { Backdrop, Box, Divider, Fade, Grid, Modal, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useShopAssignableEmployees } from '../../../components/employee/hooks'
import { useShop } from '../../../components/shop/hooks'
import ModalActionButtons from '../../../components/modals/modalComponents/ModalActionButtons'
import AssignEmployeesTable from './AssignEmployeesTable'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    padding: theme.spacing(10, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  }
}))

const reduceToSelection = (items) => items.reduce((acc, {
  id,
  selected
}) => ({ ...acc, [id]: selected }), {})

const AssignEmployeesModal = ({ onCancel, onSubmit }) => {
  const params = useParams()
  const shop = useShop(params.id)
  const items = useShopAssignableEmployees(shop.id)
  const { t } = useTranslation('oasisBackoffice')
  const classes = useStyles()

  const [currentSelection, setCurrentSelection] = useState(reduceToSelection(items))

  const onSelect = (id) => {
    setCurrentSelection({
      ...currentSelection,
      [id]: !currentSelection[id]
    })
  }

  const handleOnSubmit = () => {
    const newSelection = Object
      .entries(currentSelection)
      .filter(([, selected]) => selected)
      .map(([id]) => id)

    onSubmit(newSelection)
  }

  return (
    <Modal
      open
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in>
        <Grid item xs={11} sm={10} md={8} lg={6} xl={4}>
          <Paper elevation={24} className={classes.paper}>
            <Grid
              xs={12} sm={10}
              container
              item
              direction='column'
              alignItems='center'
              spacing={4}
            >
              <Grid item>
                <Typography
                  variant='h5'
                  color='primary'
                  gutterBottom
                >
                  <Box>{t('actionDialogs.assignEmployees') + ' ' + shop.name}</Box>
                  <Divider className={classes.divider} />
                </Typography>
              </Grid>
              <Grid item container alignItems='stretch'>
                <AssignEmployeesTable
                  items={items}
                  selected={currentSelection}
                  onSelect={onSelect}
                />
              </Grid>
              <Grid item>
                <ModalActionButtons
                  firstButtonLabel='buttonLabelAssign'
                  action={handleOnSubmit}
                  close={onCancel}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Fade>
    </Modal>
  )
}

export default AssignEmployeesModal
