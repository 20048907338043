import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import React, { useCallback } from 'react'
import { formatCreatedAt } from '../../../helpers'

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: '50vh'
  },
  cell: {
    fontSize: '14px'
  }
})

const OasisApiCallErrorsTable = ({ items }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')
  const translate = useCallback(
    (value) => t(`common.${value}`),
    []
  )

  return (
    <Grid
      container
      direction='column'
      spacing={3}
    >
      <Grid item>
        <TableContainer
          component={Paper}
          elevation={2}
          className={classes.tableContainer}
        >
          <Table
            size='small'
            aria-label='simple table'
          >
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '65%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>{t('common.date')}</TableCell>
                <TableCell>{t('common.statusCode')}</TableCell>
                <TableCell>{t('common.message')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className={classes.cell}>{formatCreatedAt(item.createdAt, translate)}</TableCell>
                  <TableCell className={classes.cell}>{item.statusCode}</TableCell>
                  <TableCell className={classes.cell}>{item.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default OasisApiCallErrorsTable
