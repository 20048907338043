import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Loading = () => {
  const classes = useStyles()
  return (
    <Backdrop
      className={classes.backdrop}
      open
    >
      <CircularProgress
        size='4rem'
        color='inherit'
      />
    </Backdrop>
  )
}

export default Loading
