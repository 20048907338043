import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const getClassNames = makeStyles((theme) => (
  {
    header: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6, 4)
    },
    content: {
      flex: 1,
      backgroundColor: theme.palette.background.default,
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingTop: theme.spacing(4)
    }
  })
)

const Layout = ({ content: Content, header: Header }) => {
  const classNames = getClassNames()

  return (
    <Grid
      component={Box}
      height='100%'
      container
      spacing={0}
      direction='column'
      item
    >
      <Grid item className={classNames.header}>
        <Header />
      </Grid>
      <Grid item className={classNames.content}>
        <Content />
      </Grid>
    </Grid>
  )
}

Layout.propTypes = {
  content: PropTypes.elementType.isRequired,
  header: PropTypes.elementType.isRequired
}

export default Layout
