import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { CancelButton, SubmitButton } from './ModalButtons'
import PropTypes from 'prop-types'
import logger from '../../../logger'
import useErrorHandler from '../../../hooks/useErrorHandler'
import { noop } from 'lodash'

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginTop: theme.spacing(1)
  }
}))

const ModalConfirmation = ({ onSubmit, onCancel, submitLabel }) => {
  const classes = useStyles()
  const handleError = useErrorHandler()

  const handleOnSubmit = async (values) => {
    try {
      values.preventDefault()
      await onSubmit(values)
    } catch (error) {
      handleError(error)
      logger.error(error.message)
    }
  }

  return (
    <Grid
      container
      direction='column'
      spacing={2}
      alignItems='stretch'
    >
      <Grid
        className={classes.actionButtons}
        item
        container
        justifyContent='center'
        spacing={2}
      >
        {onCancel && <CancelButton onClick={onCancel} />}
        <SubmitButton
          onClick={handleOnSubmit}
          label={submitLabel}
        />
      </Grid>
    </Grid>
  )
}

ModalConfirmation.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string
}

ModalConfirmation.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  submitLabel: 'buttonLabelSubmit'
}

export default ModalConfirmation
