import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ModalRoute from '../../private/ModalRoute'
import SetEmployeePassword from '../../private/actionDialogs/SetEmployeePassword'
import SendEmployeeActivationLink from '../../private/actionDialogs/SendEmployeeActivationLink'

const EmployeeRoutes = ({
  handleSendEmployeeActivationLink,
  handleSetEmployeePassword
}) => {
  return (
    <Routes>
      <Route
        path='*'
        element={
          <>
            <ModalRoute
              path='set-password'
              onSubmit={handleSetEmployeePassword}
              component={SetEmployeePassword}
            />
            <ModalRoute
              path='activate-account'
              onSubmit={handleSendEmployeeActivationLink}
              component={SendEmployeeActivationLink}
            />
          </>
        }
      />
    </Routes>
  )
}

export default EmployeeRoutes
