import TableCell from '@material-ui/core/TableCell'
import React from 'react'
import ActionsMenu from './ActionsMenu'

const TableActionsCell = ({ actions }) =>
  <TableCell
    style={{ width: 62 }}
    className='actions'
  >
    <ActionsMenu actions={actions} />
  </TableCell>

export default TableActionsCell
