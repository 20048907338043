import { useMemo } from 'react'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import { useShop } from '../shop/hooks'

export const useShopLicenses = (shopId) => {
  const [{ licenses }] = useBackOffice()

  return useMemo(
    () => licenses.ids.filter((id) => licenses.byId[id].shop === shopId),
    [shopId, licenses]
  )
}

export const useOwnerAssignableLicenses = (id) => {
  const [state] = useBackOffice()

  return useMemo(
    () => state.licenses.ids
      .filter((licenseId) => state.licenses.byId[licenseId].owner === id || !state.licenses.byId[licenseId].owner)
      .map((licenseId) => ({
        id: licenseId,
        licenseNumber: state.licenses.byId[licenseId].licenseNumber,
        validUntil: state.licenses.byId[licenseId].validUntil,
        used: !!state.licenses.byId[licenseId].owner
      })),
    [id, state.licenses]
  )
}

export const useShopAssignableLicenses = (shopId) => {
  const shop = useShop(shopId)
  const [state] = useBackOffice()

  return useMemo(
    () => state.licenses.ids
      .filter((licenseId) => {
        const license = state.licenses.byId[licenseId]
        return license.shop === shopId || (!license.shop && license.owner === shop.owner) || !license.owner
      })
      .map((licenseId) => ({
        id: licenseId,
        licenseNumber: state.licenses.byId[licenseId].licenseNumber,
        validUntil: state.licenses.byId[licenseId].validUntil,
        used: !!state.licenses.byId[licenseId].shop
      })),
    [shopId, state.licenses]
  )
}

export const useAssignLicenses = (licenses, onClick) => ({
  disabled: !licenses.length,
  label: licenses.length ? 'assignLicenses' : 'noUnassignedLicenses',
  onClick
})
