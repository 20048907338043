import english from '../translations/en-US'
import german from '../translations/de-DE'

export const ns = Object.keys(english.resources)

export const resources = {
  'en-US': english.resources,
  'de-DE': german.resources
}

export const localeData = [
  english.locale,
  german.locale
]

export const supportedLngs = Object.keys(resources).map(lang => lang.split('-')[0])
