import { useParams } from 'react-router'
import { useOwnerAssignableLicenses } from '../../license/hooks'
import { useOwner } from '../../owner/hooks'
import AssignLicenses from './AssignLicenses'
import { useTranslation } from 'react-i18next'

const AssignLicensesToOwner = ({ onCancel, onSubmit }) => {
  const params = useParams()
  const owner = useOwner(params.id)
  const licenses = useOwnerAssignableLicenses(owner.id)
  const { t } = useTranslation('oasisBackoffice')

  return (
    <AssignLicenses
      label={`${t('common.assignLicensesTo')} ${owner.name}`}
      licenses={licenses}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  )
}

export default AssignLicensesToOwner
