import React from 'react'
import { alpha, Divider, Paper, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import BgImage from '../../images/bg-oasis.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    backgroundColor: theme.palette.primary.main
  },
  errorIcon: {
    fontSize: 180,
    color: alpha(theme.palette.primary.main, 0.5)
  },
  errorMessage: {
    padding: theme.spacing(1, 0, 0, 0)
  },
  paperBox: {
    padding: '100px 200px'
  },
  mainContainer: {
    height: '100%',
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
    backgroundSize: 'cover'
  }
}))

const OasisErrorPage = () => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Grid
      container
      item
      className={classes.mainContainer}
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Paper className={classes.paper}>
        <Grid
          className={classes.paperBox}
          container
          item
          direction='column'
          alignItems='center'
        >
          <Grid item container direction='column' alignItems='center'>
            <Typography
              variant='h5'
              color='primary'
              gutterBottom
            >
              <Box>{t('oasisErrorPage.errorOccurred')}</Box>
              <Divider className={classes.divider} />
            </Typography>
            <SentimentVeryDissatisfiedIcon className={classes.errorIcon} />
            <Typography
              variant='body1'
              color='primary'
              className={classes.errorMessage}
            >
              {t('oasisErrorPage.errorMessage')}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default OasisErrorPage
