import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { FieldTypes } from './fields'
import OasisFormButtons from './OasisFormButtons'
import OasisFormField, { FieldPropTypes } from './OasisFormField'
import OasisFormWrapper from './OasisFormWrapper'

const OasisForm = ({ defaultValue, fields, buttonLabel, backHref, onSubmit }) => {
  const reCaptchaRef = useRef(null)

  // TODO: find a better way to manage reCaptcha.reset on failed requests
  const handleOnSubmit = (formData, setErrors) => {
    // need to grab the setErrors call to reset recaptcha
    const handleErrors = (...args) => {
      reCaptchaRef.current?.reset?.()
      setErrors(...args)
    }
    onSubmit(formData, handleErrors)
  }

  const formControls = Object.entries(fields).map(
    ([name, field], index) => (
      <OasisFormField
        ref={field.type === FieldTypes.RECAPTCHA ? reCaptchaRef : null}
        key={name}
        name={name}
        autoFocus={index === 0}
        field={field}
      />
    )
  )

  return (
    <OasisFormWrapper
      defaultValue={defaultValue}
      fields={fields}
      onSubmit={handleOnSubmit}
    >
      {({ submitDisabled, isSubmitting }) =>
        <>
          {formControls}
          <OasisFormButtons
            backHref={backHref}
            isSubmitting={isSubmitting}
            submitDisabled={submitDisabled}
            submitLabel={buttonLabel}
            spacing={3}
            mt={6}
          />
        </>}
    </OasisFormWrapper>
  )
}

OasisForm.propTypes = {
  fields: PropTypes.objectOf(FieldPropTypes),
  defaultValue: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
  formSubmit: PropTypes.func,
  buttonLabel: PropTypes.string,
  backHref: PropTypes.string
}

OasisForm.defaultProps = {
  fields: {},
  onSubmit: () => {},
  buttonLabel: '',
  backHref: ''
}

export default OasisForm
