import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import React from 'react'
import ButtonWithLoader from '../../../components/common/ButtonWithLoader'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

export const CancelButton = ({ onClick }) => {
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Grid item>
      <Button
        variant='outlined'
        color='primary'
        onClick={() => onClick()}
      >
        {t('oasisForm.buttonLabelCancel')}
      </Button>
    </Grid>
  )
}

CancelButton.propTypes = {
  onClick: PropTypes.func
}

CancelButton.defaultProps = {
  onClick: noop
}

export const SubmitButton = ({ isSubmitting, submitDisabled, label, onClick }) => {
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Grid item>
      <ButtonWithLoader
        loading={isSubmitting}
        variant='contained'
        color='primary'
        disabled={submitDisabled}
        type='submit'
        onClick={onClick}
      >
        {t(`oasisForm.${label}`)}
      </ButtonWithLoader>
    </Grid>
  )
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func
}

SubmitButton.defaultProps = {
  isSubmitting: false,
  submitDisabled: false,
  label: 'buttonLabelSubmit',
  onClick: noop
}
