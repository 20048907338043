import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { getFormikValidateFn } from './helpers'
import { FieldPropTypes } from './OasisFormField'
import { appVersion } from '../Footer/consts'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: '100vw',
    '@media (min-width:328px)': {
      minWidth: '328px',
      maxWidth: '328px'
    }
  },
  appVersion: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    color: 'white',
    fontSize: '0.8rem',
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5)
  }
}))

const OasisFormWrapper = ({ defaultValue, fields, onSubmit, children }) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')
  const validate = getFormikValidateFn(fields, t)
  const handleOnSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    try {
      await onSubmit(values, setErrors)
    } catch {
      // need this only for errors, success unmounts the form
      setSubmitting(false)
    }
  }

  return (
    <Formik
      validateOnMount
      initialValues={defaultValue}
      validate={validate}
      onSubmit={handleOnSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty, ...formikProps }) =>
        <Form
          noValidate
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Grid
            container
            direction='column'
            spacing={2}
            alignItems='stretch'
          >
            {children({
              handleSubmit,
              isSubmitting,
              submitDisabled: !dirty || isSubmitting || !isValid,
              ...formikProps
            })}
            <Typography className={classes.appVersion}>v{appVersion}</Typography>
          </Grid>
        </Form>}
    </Formik>
  )
}

OasisFormWrapper.propTypes = {
  defaultValue: PropTypes.objectOf(PropTypes.string),
  fields: PropTypes.objectOf(FieldPropTypes),
  onSubmit: PropTypes.func
}

OasisFormWrapper.defaultProps = {
  fields: {},
  defaultValue: {},
  onSubmit: () => {}
}

export default OasisFormWrapper
