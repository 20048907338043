import { useEffect, useReducer } from 'react'
import Distributor from '../components/distributor/Distributor'
import useAuth from '../hooks/useAuth'
import logger from '../logger'
import { FETCH_DISTRIBUTORS } from '../reducer/actions'
import backOfficeReducer from '../reducer/reducer'
import { api } from '../services/api'
import { BackOfficeContext } from './hooks/useBackOffice'

const initState = () => ({
  distributor: null,
  distributors: {},
  owners: {},
  licenses: {},
  shops: {},
  scans: {},
  oasisApiCalls: {}
})

const { fetchDistributors } = api

const BackOffice = () => {
  const { logout, token } = useAuth()
  const [state, dispatch] = useReducer(backOfficeReducer, {}, initState)

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          logger.info('Loading distributors')
          dispatch({ type: FETCH_DISTRIBUTORS, payload: await fetchDistributors() })
        } catch (error) {
          logger.error(error.message)
          logout()
        }
      }

      fetchData()
    },
    [token]
  )

  return (
    <BackOfficeContext.Provider value={[state, dispatch]}>
      {!!state.distributor && <Distributor id={state.distributor} />}
    </BackOfficeContext.Provider>
  )
}

export default BackOffice
