import { AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DateRangeIcon from '@material-ui/icons/DateRange'
import PropTypes from 'prop-types'
import React from 'react'
import ActionsMenu from './ActionsMenu'
import { useTranslation } from 'react-i18next'
import AuthorizedView from './AuthorizedView'
import { oasisApiCallResults } from '../../helpers'
import { AvailableFeatures } from '../../private/const'

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    order: 1,
    marginLeft: -12,
    marginRight: 0
  },
  content: {
    order: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1)
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    '& button': {
      opacity: 0.3,
      transition: theme.transitions.create('opacity')
    },
    '&:hover button': {
      opacity: 1
    }
  }
}))

const AccordionSummaryWithActions = ({
  actions,
  children,
  disabled,
  header,
  scanCount,
  shopSchedule,
  oasisApiCalls = []
}) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  const [successOasisApiCalls, errorOasisApiCalls] = oasisApiCallResults(oasisApiCalls)
  const oasisApiSuccessCount = successOasisApiCalls.length
  const oasisApiErrorCount = errorOasisApiCalls.length

  return (
    <AccordionSummary
      classes={classes}
      disabled={disabled}
      expandIcon={<ExpandMoreIcon />}
    >
      {children}
      <Typography variant='body1' style={{ flex: 1 }}>
        {header}
      </Typography>
      {shopSchedule && (
        <Typography
          variant='body1'
          style={{ display: 'flex', flex: 3, alignItems: 'center' }}
        >
          <DateRangeIcon
            style={{
              marginRight: '0.5em',
              verticalAlign: 'middle'
            }}
            color='disabled'
          />
          {shopSchedule}
        </Typography>
      )}
      <AuthorizedView>
        <Typography variant='caption' style={{ flex: 1 }}>
          {`${t('common.scanCount')}: ${scanCount || '-'}`}
        </Typography>
      </AuthorizedView>
      <AuthorizedView features={[AvailableFeatures.oasisApiCounting]}>
        <Typography variant='caption' style={{ flex: 1 }}>
          {`${t('common.oasisApiCalls')}: ${oasisApiSuccessCount || '-'} / ${oasisApiErrorCount || '-'}`}
        </Typography>
      </AuthorizedView>
      <ActionsMenu actions={actions} />
    </AccordionSummary>
  )
}

AccordionSummaryWithActions.propTypes = {
  header: PropTypes.node,
  scanCount: PropTypes.number,
  shopSchedule: PropTypes.string,
  oasisApiCalls: PropTypes.arrayOf(PropTypes.object)
}

export default AccordionSummaryWithActions
