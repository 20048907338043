// import locale from 'i18next-icu/locale-data/en'
import oasisBackoffice from './oasisBackoffice.json'
import common from './common.json'

const translationConfig = {
  // locale,
  resources: {
    common,
    oasisBackoffice
  }
}

export default translationConfig
