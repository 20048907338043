import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ButtonWithLoader from '../components/common/ButtonWithLoader'
import { emailInput, passwordInput, reCaptchaField } from '../components/OasisForm/fields'
import { fieldsToForm } from '../components/OasisForm/helpers'
import OasisFormField from '../components/OasisForm/OasisFormField'
import OasisFormWrapper from '../components/OasisForm/OasisFormWrapper'
import { isAuthErrorCode } from '../helpers'
import useAuth from '../hooks/useAuth'
import logger from '../logger'
import publicRoutes from './routes'
import { useTranslation } from 'react-i18next'
import { reCaptchaActions } from '../services/api/const'

const fields = {
  email: {
    ...emailInput,
    label: 'fieldLabelLogin',
    required: true,
    InputProps: {
      autoComplete: 'email'
    }
  },
  password: {
    ...passwordInput,
    required: true,
    InputProps: {
      autoComplete: 'current-password'
    }
  },
  reCaptcha: { ...reCaptchaField, action: reCaptchaActions.login }
}

const defaultValue = fieldsToForm(fields)

const useStyles = makeStyles((theme) => ({
  createAccount: {
    color: theme.palette.primary.main
  },
  createAccountLink: {
    color: theme.palette.primary.main
  },
  forgottenPasswordLink: {
    textDecoration: 'underline',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    display: 'block',
    color: theme.palette.primary.main
  },
  button: {
    marginInline: 'auto',
    width: '200px',
    marginTop: theme.spacing(2)
  }
}))

const Login = () => {
  const reCaptchaRef = useRef(null)
  const auth = useAuth()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  const handleOnSubmit = async (formData, setErrors) => {
    await reCaptchaRef.current?.reset?.()
    setSubmitInProgress(true)
    try {
      await auth.login(formData)
    } catch (error) {
      logger.error(`${error.message}: ${JSON.stringify(error.error)}`)
      if (isAuthErrorCode(error.status)) {
        setErrors({
          email: ' ',
          password: t('errors.invalidCredentials')
        })
      }
      setSubmitInProgress(false)
    }
  }

  return (
    <OasisFormWrapper
      defaultValue={defaultValue}
      fields={fields}
      onSubmit={handleOnSubmit}
    >
      {({ onSubmit, submitDisabled }) =>
        <>
          <OasisFormField
            name='email'
            autoFocus
            field={fields.email}
          />
          <OasisFormField
            name='password'
            field={fields.password}
          />
          <OasisFormField
            ref={reCaptchaRef}
            name='reCaptcha'
            field={fields.reCaptcha}
          />
          <Link
            className={classes.forgottenPasswordLink}
            to={publicRoutes.forgotPassword}
          >
            {t('login.forgotPassword')}
          </Link>
          <ButtonWithLoader
            className={classes.button}
            type='submit'
            variant='contained'
            color='primary'
            loading={submitInProgress}
            disabled={submitDisabled}
            onClick={onSubmit}
          >
            {t('login.loginButtonLabel')}
          </ButtonWithLoader>
          <Box
            display='flex'
            justifyContent='center'
            mt={2}
          >
            <Typography
              variant='body1'
              color='primary'
              className={classes.createAccount}
            >
              ...{t('login.or')} <Link to={publicRoutes.signUp} className={classes.createAccountLink}>{t('login.createAccount')}</Link>
            </Typography>
          </Box>
        </>}
    </OasisFormWrapper>
  )
}

export default Login
